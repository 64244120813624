import React from "react";
import "./ErrorModal.css"; // Import the CSS for the modal

const ErrorModal = ({ message, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="errorModal">
        <div className="errorModal-header">
          <h2>Greška</h2>
          <button className="modal-close" onClick={onClose}>
            ×
          </button>
        </div>
        <div className="errorModal-content">
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
