import React from "react";

function SectionTestimonials() {
  return (
    <section className="section-testimonials" id="testimonials">
      <div className="container center-text">
        <span className="subheading"></span>
        <h2 className="heading-secondary">Najuspešniji koriste Akord....</h2>

        <div className="container grid grid--2-cols margin-bottom-md">
          <figure className="testimonial">
            <img
              className="testimonial-img"
              alt="Photo of customer Branko Ćulum"
              src="/poslovni-softver/img/customers/branko.jpg"
            />
            <blockquote className="testimonial-text">
              Sve naše poslovne aktivnosti u Srbiji i Bosni i Hercegovini
              obavljamo na Akord sofveru od 2015 godine kada smo ga
              implementirali. Postoje mnogi poslovni programi, ali je bitno da
              mogu ispratiti uvoz robe, veleprodajne, maloprodajne i servisne
              aktivnosti i naravno sve ono što zahtevaju
              knjigovodstveno-računovodstveni poslovi. Mi poslujemo od 1997.
              godine i danas je program za poslovanje nešto što je neophodno.
              Moram da priznam da je implementacija Akord softvera bila izazova
              za obe strane, ali je na kraju dovela do toga da smo evo 9 godina,
              više nego zadovoljan korisnik.
            </blockquote>
            <p className="testimonial-name">
              Branko Ćulum - vlasnik i direktor
              <br />
            </p>
            <p className="testimonial-name">
              <a
                href="http://bodena.co.rs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong> Bodena d.o.o. </strong> Temerin
              </a>
            </p>
          </figure>
          <figure className="testimonial">
            <img
              className="testimonial-img"
              alt="Photo of Mila Gudelj"
              src="/poslovni-softver/img/customers/mila.jpg"
            />
            <blockquote className="testimonial-text">
              Mi koji smo odgovorni za računovodstvo i finansije u komunalnim
              preduzećima znamo koliko je bitno da imamo dobar i stabilan
              softver za praćenje poslovanja. Nama koji radimo u računovodstvu
              JKP Komunalac Vrbas je drago da smo imali sreću da na javnoj
              nabavci 2015. godine dobijemo ovako stabilan i sveobuhvatan
              poslovni program za knjigovodstvo, finansije, obračun plata,
              osnovna sredstva, ali i obradu naših specifičnih poslova sa kojima
              se susrećemo kao komunalno preduzeće. Rado preporučujem Akord
              softver svim kolegama u komunalnim preduzećima.
            </blockquote>
            <p className="testimonial-name">
              Mila Gudelj, šef računovodstva
              <br />
            </p>
            <p className="testimonial-name">
              <a
                href="http://komunalacvrbas.rs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong> JKP Komunalac </strong> Vrbas
              </a>
            </p>
          </figure>

          <figure className="testimonial">
            <img
              className="testimonial-img"
              alt="Photo of customer Vladimir Kotroman"
              src="/poslovni-softver/img/customers/kotroman.jpeg"
            />
            <blockquote className="testimonial-text">
              Počeli smo koristiti Akord 2009 godine u vreme kada je bila samo
              Windows verzija i bili smo više nego zadovoljni. Sa pojavom Klaud
              tehnologija i verzije Akorda koja to podržava odlučili smo da
              pređemo na novu verziju. Klaud tehnologije donose mnoga olakšanja
              po pitanju eliminacije servera u firmi i održavanja mreže i danas
              smo jako zadovoljni zbog odluke da pređemo na novu tehnologiju.
              Uvoz i prodaja kamiona, automobila, rezervnih delova i održavaje
              vozila su jako kompleksne delatnosti i zahtevaju moćan poslovni
              softver. Akord softver je zadovoljio sve naše potrebe
            </blockquote>
            <p className="testimonial-name">
              Vladimir Kotroman - vlasnik
              <br />
            </p>
            <p className="testimonial-name">
              <a
                href="http://euromodus.ba"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong> Euromodus d.o.o. </strong> Banja Luka
              </a>
            </p>
          </figure>

          <figure className="testimonial">
            <img
              className="testimonial-img"
              alt="Photo of customer Branislav Pavlović"
              src="/poslovni-softver/img/customers/bane.jpg"
            />
            <blockquote className="testimonial-text">
              Program za knjigovodstvo Akord koristimo od 2014. godine i lično
              sam učestvovao u razvoju nekih funkcionalnosti koje su nama
              knjigovođama od krucijalne važnosti. Naša agencija je
              specijalizovana za knjigovodstvo i finansije i imamo preko 70
              klijenata koji koriste naše usluge. Moram da napomenem da Klaud
              tehnologije imaju svoje specifičnosti i da je potrebno vreme da se
              korisnici naviknu na njih i to posebno, ako se Akord koristi kao
              softver za knjigovodstvo. Moje kolege su navikle da unose podatke
              sa papira, a sada je vreme elektronskih dokumenata koji postaju
              standard.
            </blockquote>
            <p className="testimonial-name">
              Branislav Pavlović - Vlasnik i direktor
              <br />
            </p>
            <p className="testimonial-name">
              <a href="https://www.portfolio.rs/" target="_blank" rel="noopener noreferrer">
                <strong> Portfolio d.o.o. </strong> Beograd
              </a>
            </p>
          </figure>

          <figure className="testimonial">
            <img
              className="testimonial-img"
              alt="Photo of Dumnić Pedja"
              src="/poslovni-softver/img/customers/peđa.jpg"
            />
            <blockquote className="testimonial-text">
              Naša kompanija je ekskluzivni distributer brenda Proteini.si za
              tržište Republike Srbije. Pored standarnih veleprodajnih aktivnosti
              imamo i one koje su specifične i vrlo složene u odnosu na klasične
              trgovačke poslovne procese. Da bi pokrili te složene potrebe u
              poslovanju dugo smo bili u potrazi za softverom koji će nam
              omogućiti da pratimo sve ono što je svakodnevnica našeg
              poslovanja. U početku smo radili na softveru proizvedenom u
              Sloveniji, ali je kasnio sa usaglašavanjem zakonskih zahteva koji
              su donošeni u Srbiji pa smo odlučili da implementiramo domaći
              softver. Implementacijom Akord softvera 2020. godine uspeli smo u
              potpunosti softverski obuhvatiti naše poslovne procese, a sam tim
              i rešiti naš veliki problem.”
            </blockquote>
            <p className="testimonial-name">
              Peđa Dumnić - direktor
              <br />
            </p>
            <p className="testimonial-name">
              <a
                href="https://rs.proteini.si/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong> D-Sport d.o.o. </strong> Novi Sad
              </a>
            </p>
          </figure>

          <figure className="testimonial">
            <img
              className="testimonial-img"
              alt="Photo of customer Hannah Smith"
              src="/poslovni-softver/img/customers/vule.jpg"
            />
            <blockquote className="testimonial-text">
              ”Naša firma, koja se bavi prodajom opreme za mobilne telefone,
              koristi poslovni softver Akord od 2016. godine, i od tada je
              postao neizostavan deo našeg poslovanja. Zahvaljujući ovom
              softveru, značajno smo unapredili efikasnost u svakodnevnim
              operacijama – od nabavke i prodaje, pa sve do računovodstva i
              obračuna plata. Posebno nam je korisna njegova funkcionalnost za
              upravljanje zalihama i kasama u maloprodajama, jer imamo praćenje
              lagera u realnom vremenu, što doprinosi preciznom planiranju i
              optimizaciji resursa. Tim za podršku je uvek dostupan i spreman da
              pruži rešenja za sva pitanja ili izazove. Možemo reći da je ovaj
              poslovni softver značajno doprineo rastu našeg poslovanja, a s
              obzirom na njegovu fleksibilnost i stalno unapređivanje, verujemo
              da će ostati naš ključni alat u godinama koje dolaze.”
            </blockquote>
            <p className="testimonial-name">
              Vukašin Katić - Direktor i vlasnik
              <br />
            </p>
            <p className="testimonial-name">
              <a
                href="https://mobilland.rs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong> Mobille Exclusive d.o.o. </strong> Novi Sad
              </a>
            </p>
          </figure>
          <div />
        </div>
      </div>
    </section>
  );
}

export default SectionTestimonials;
