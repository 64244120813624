import React from "react";
import { useNavigate } from "react-router-dom";
import { FaCoins, FaPenNib, FaFilm } from "react-icons/fa";
import VideoPlayer from "../VideoPlayer";


export default function Micro() {

  const navigate = useNavigate();  
  const [showVideo, setShowVideo] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState("");


  const handleButtonClick = (path) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(path.split("#")[1]);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };

  const handleVideoClick = (url) => {
    setVideoUrl(url);
    setShowVideo(true);
  };

  const handleVideoClose = () => {
    setShowVideo(false);
    setVideoUrl("");
  };

  return (
    <>
      <section className="section-hero" id="start">
        <div className="hero">
          <div className="hero-text-box">
            <h1 className="heading-primary">
              Akord Micro - softver za robno-materijalno poslovanje
            </h1>
            <p className="hero-description">
              Temelj uspešnog poslovanja je striktno praćenje zaliha materijala,
              robe i inventara. <br />
              Bez obzira na veličinu vašeg preduzeća, Akord Micro će vam pomoći
              da pratite sve vaše artikle, po skladištima i lokacijama, da
              izdajete i primite robu, da kreirate i štampate dokumenta kao što
              su otpremnice, prijemnice, magacinske kartice, kalkulacije,
              narudžbenice, radne naloge, itd. <br />
              <br /> Postanite i vi zadovoljni Akord Micro korisnik!
              <br />
            </p>
            <button
              onClick={() => handleButtonClick("/#cta")}
              className="btn btn--outline-white margin-right-sm"
            >
              Postanite korisnik <FaPenNib className="icon" />
            </button>
            <button
              onClick={() => handleButtonClick("/#pricing")}
              className="btn btn--outline-white "
            >
              Cena licence <FaCoins className="icon" />
            </button>
          </div>
          <div className="hero-img-box">
            <picture>
              <source srcSet="img/micro500.png" type="image/png" />
              <img
                src="/poslovni-softver/img/micro2.png"
                className="hero-img"
                alt="Entrepreneur with a scale"
              />
            </picture>
          </div>
        </div>
      </section>
      <section className="section-how" id="aboutus">
        <div className="container">
          <span className="subheading"></span>
          <h2 className="heading-secondary">
            Evidencija robe i materijala - preduslov uspešnog poslovanja
          </h2>
          <div className="container-video">
            <video autoPlay muted loop>
              <source src="/poslovni-softver/video/servers1020.mp4" type="video/mp4" />
              Vaš pregledač ne podržava video klipove.
            </video>
          </div>
          <div className="row">
            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-world"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Zalike su vaša imovina
                </h3>
                <p className="feature-box__text">
                  Čuvajte ono što je vaše! <br />
                  <br />
                  Vođenje evidencije o stanju zaliha, obaveza prema dobavljačima
                  i potraživanja od kupaca je osnovni zadatak svakog
                  preduzetnika.
                  <br />
                  Softver podržava štampanje magacinskih kartica, otpremnica,
                  kalkulacija, prijemnica, radnih naloga, narudžbenica, itd.
                  <br /> Jednako dobro podržava procese u veleprodaji i
                  maloprodaji, a kase mogu da rade u online ili offline režimu.
                  <br />
                  Ukoliko korisnik pređe na Akord Basic, sva istorija poslovanja
                  je sačuvana.
                  <br />
                  <br />
                  Naša video upustva su tu da pomognu.
                </p>
              </div>
            </div>

            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-compass"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Nabavka robe i materijala
                </h3>
                <p className="feature-box__text">
                  Administriranje je prvi proces i podrazumeva unos dobavljača,
                  artikala i početni stanja.
                  <br />
                  <br />
                  Sve dalje aktivnosti vezane su za dokumenta nabavke kao što su
                  nalog za nabavku, prijemnica, kalkulacija, itd. <br />
                  <br /> Omogućeno je praćenje uplata dobavljačima, unos zavisni
                  troškova i povezivanje uplata za robu, usluge i troškove.
                  <br />
                  <br /> Sve procese prate kvalitetni on-line izveštaji.
                </p>
                <div className="plan-sing-up">
                  <button
                    onClick={() =>
                      handleVideoClick("https://www.youtube.com/watch?v=8bcF1nFXUPs")
                    }
                    className="btn btn--outline-white"
                  >
                    Uputstvo
                    <FaFilm className="icon" />
                  </button>
                </div>
              </div>
            </div>

            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-map"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Veleprodaje i maloprodaje
                </h3>
                <p className="feature-box__text">
                  Veleprodajne aktivnosti se mogu pratiti na jednom ili više
                  skladišta i lokacija.
                  <br />
                  Sve aktivnosti su praćene izveštajima propisanim zakonom ili
                  su posebno podešeni prema zahtevima korisnika. <br /> <br />
                  Podešavanja se rade kroz šifarnike i lako se menjaju po
                  potrebi.
                  <br />
                  <br />
                  Maloprodajno poslovanje je podržano radom kasa u on-line ili
                  off-line režimu, a svaka maloprodaja se prati kao poseban
                  objekat pa se tako vide aktivnosti tog objekta i poslovanje u
                  njemu. <br />
                </p>
                <div className="plan-sing-up">
                  <button
                    onClick={() =>
                      handleVideoClick("https://www.youtube.com/watch?v=8bcF1nFXUPs")
                    }
                    className="btn btn--outline-white"
                  >
                    Uputstvo
                    <FaFilm className="icon" />
                  </button>
                </div>
              </div>
            </div>

            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-heart"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Dokumenti i izveštaji
                </h3>
                <p className="feature-box__text">
                  Podržani su svi prodajni dokumeti jer je ovo softver za:
                  ponude, profakture, fakture, maloprodajne račune, izvozne
                  račune, povratnice i naravno, storniranje svih dokumenata po
                  potrebi. <br /> <br />
                  Softver podržava rad sa gotovinskim i avansnim računima, sa i
                  bez PDV-a, više valuta, ali i evidentiranje uplata i plaćanja
                  i njihovo povezivanje. <br /> <br />
                  Izveštaji kao što su sintetike dokumenata pregled kupaca i
                  dobavljača i stanja zalika samo su neki od standarno podržani.
                  <br />
                </p>
                <div className="plan-sing-up">
                  <button
                    onClick={() =>
                      handleVideoClick("https://www.youtube.com/watch?v=ASj0jIJUErM")
                    }
                    className="btn btn--outline-white"
                  >
                    Uputstvo
                    <FaFilm className="icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showVideo && <VideoPlayer url={videoUrl} onClose={handleVideoClose} />}
    </>
  );
}
