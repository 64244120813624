import React from "react";
import JobListing from "./JobListing";

function Recruitment() {
  const jobListings = [
    {
      id: 1,
      title: "Konsultant za knjigovodstveno-računovodstvene poslove",
      description:
        "Ovo radno mesto podrazumeva da poznajete knjigovodstvene procese i da ste samostalno radili završne račune. Dodatna pojašnjenja imate na strani koja se otvara linkom ispod.",
      link: "/job/1",
    },
    {
      id: 2,
      title: "Konsultant za procese u robno-materijalnom knjigovodstvu",
      description:
        "Ovo radno mesto podrazumeva da poznajete robno-materijalno knjigovodstvo i svu dokumentaciju koja se koristi u tim procesima. Ukoliko želite više informacija kliknite na link ispod.",
      link: "/job/2",
    },
    {
      id: 3,
      title: "Konsultant za procese obračuna plata",
      description:
        "Ovo radno mesto podrazumeva da poznajete procese obračuna plata i da ste to samostalno radili u dužem periodu i da Vam je poznato više vrsta obračuna. Dodatna pojašnjenja imate na strani koja se otvara linkom ispod.",
      link: "/job/3",
    },
    {
      id: 4,
      title: "Administrator dokumenata u kompaniji",
      description:
        "Ovo radno mesto ne podrazumeva specifična znanja, ali se traži odgovornost i sistemetičnost kao personalna osobina. Treba da ste skloni timskom radu i da imate visok stepen empatije prema drugima. Dodatna pojašnjenja imate na strani koja se otvara linkom ispod.",
      link: "/job/4",
    },
  ];

  return (
    <>
      <section className="career--baner">
        <div className="wreper--iner">
          <h1 className="career--heding">
            Mi smo više od zaposlenja. Mi smo familija!
          </h1>
          <p className="career--text">
            Dug je put do uspešne karijere i samo oni koji su potpuno posvećeni
            i računaju na duge staze je postižu. Naš cilj je da Vam olakšamo taj
            put i da stvorimo radno okruženje koje obogaćuje pojedinca i koje
            stvara svakodnevne izazove.
          </p>

          <div className="career--baner-img">
            <img src="/poslovni-softver/img/background-rec-small.webp" alt="Recroutment image" />
          </div>
          <div className="career--btn">
            <a className="btn" href="#rec">
              Otvorena mesta
            </a>
          </div>
        </div>
      </section>
      <section className="career--opening" id="rec">
        <div className="career--open-wrapper">
          <h1>Neke prilike možda za tebe?!</h1>
          <div className="embed--jobs">
            <ul className="rec--group">
              {jobListings.map((job) => (
                <JobListing
                  key={job.id}
                  title={job.title}
                  description={job.description}
                  link={job.link}
                />
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Recruitment;
