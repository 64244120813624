import React from "react";

function TermsModal({ onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>Uslovi korišćenja</h2>
          <button className="modal-close" onClick={onClose}>
            ×
          </button>
        </div>
        <div className="modal-content-terms">
          <ol className="custom-list">
            <h4>Opšti uslovi</h4>
            <li>
              Registracijom naloga stiče se status korisnika kojem je kompanija
              Ada Soft d.o.o. dozvolila korišćenje poslovnog programa Akord
              Softver.
            </li>
            <li>
              Kompanija Ada Soft d.o.o., Pere Popadića 7, Novi Sad, Srbija
              poseduje kompletna autorska prava nad Akord Softver verzijama i
              ima status proizvođača aplikacije.
            </li>
            <li>
              Procesom registrovanja, podatke koje korisnik unese smatraju se
              poslovnom tajnom i zaštićeni su Zakonom. Koriste se isključivo u svrhu registrovanja i slanja
              obaveštenja korisnicima. Neće biti korišćeni ni u kakve druge
              svrhe niti će biti na uvidu trećim licima, izuzev uz pismeni
              zahtev državni organa.
            </li>
            <li>
              Korisnik se obavezuje da će se registrovati koristeći isključivo
              svoje podatke i da će ih čuvati od neovlašćenog pristupa trećih
              lica. U slučaju korišćenja tuđih podataka, korisnik snosi
              odgovornost za štetu koja može nastati.
            </li>
            <li>
              Probni period korišćenja Akord softvera je 10 dana. Nakon isteka probnog perioda, korisnik je
              dužan da plati korišćenje programa prema važećem cenovniku.
            </li>
            <li>
              Akord Softver svojom uslugom ne obuhvata pružanje
              telekomunikacionih usluga, niti usluga pristupa internetu.
              Korisnik je dužan da obezbedi pristup internetu kako bi koristio
              Akord Softver i Ada Soft d.o.o. na ove usluge ne može uticati i ne
              snosi odgovornost za njihov kvalitet.
            </li>
            <li>
              Korisnik je dužan da čuva svoje korisničko ime i lozinku i da ih
              ne deli sa trećim licima. U slučaju zloupotrebe korisničkog
              naloga, korisnik snosi odgovornost za štetu koja može nastati.
              Korisnik mora da obavesti Ada Soft d.o.o. o zloupotrebi naloga
              ukoliko do nje dođe.
            </li>
            <li>
              Ada Soft d.o.o. zadržava pravo da u bilo kom trenutku obustavi
              pružanje usluge korisniku koji krši uslove korišćenja ili koji
              ugrožava sigurnost aplikacije ili koristi aplikaciju na način da
              se pretpostavi da do toga može doći.
            </li>
            <li>
              Korišćenje programa od strane korisnika ne sme biti u suprotnosti
              sa zakonima Republike Srbije. Korisnik je dužan da poštuje zakone
              i propise Republike Srbije i da ne koristi program za nezakonite
              radnje. 
            </li>
            <li>
              Iznos za korišćenje programa se plaća unapred, a korisnik dobija
              račun na ime uplate. U slučaju neplaćanja, Ada Soft d.o.o.
              zadržava pravo da obustavi pružanje usluge. Cena korišćenja
              programa se može promeniti uz predhodnu najavu u roku ne kraćem od
              60 dana.
            </li>
            <li>
              Uslovi korišćenja poslovnog program Akord Softver su sastavni deo
              Ugovora o korišćenju programa koji korisnik potpisuje prilikom
              registracije naloga i važe od trenutka potpisivanja do pismenog
              prekida ili raskida ugovora.
            </li>
            <li>
              Ukoliko korisnik ne uplati naknadu za korišćenje programa u roku
              od 180 dana od dana dospeća, Ada Soft d.o.o. zadržava pravo da
              jednostrano raskine ugovor o korišćenju programa i obustavi
              pružanje usluge. U tom slučaju, korisnik gubi pravo na povraćaj
              sredstava, a njegovi podaci se brišu iz baze podataka bez
              mogućnosti povratka ili nadoknade štete.
            </li>
            <li>
              Uslovi korišćenja poslovnog program Akord Softver su sastavni deo
              Ugovora o korišćenju programa koji korisnik potpisuje prilikom
              registracije naloga i važe od trenutka potpisivanja do pismenog
              prekida ili raskida ugovora.
            </li>
          </ol>

          <ol className="custom-list">
            <h4>Uslovi plaćanja</h4>
            <li>
              Nakon isteka probnog perioda, korisnik je dužan da uplati iznos za
              korišćenje programa u roku od 5 dana od dana isteka probnog
              perioda. Isti rok uplate je i u slučaju isteka perioda za koji je
              postojala prethodna uplata. Ukoliko korisnik ne uplati naknadu za
              korišćenje programa u roku od 5 dana od dana dospeća, Ada Soft
              d.o.o. zadržava pravo da obustavi pružanje usluge.
            </li>
            <li>
              Ada Soft d.o.o. zadržava pravo da promeni cene korišćenja programa
              uz prethodnu najavu. Ukoliko korisnik
              ne prihvati novu cenu, može raskinuti ugovor o korišćenju programa
              bez dodatnih troškova. Najava
              izmena cena se vrši putem obaveštenja, mejlom, na sajtu
              www.akord.rs ili putem obaveštenja u programu Akord Softver.
            </li>
            <li>
              Korisnik može da uplate vrši na mesečnom ili godišnjem
              nivou.
              Svaka avansna uplata od 12 meseci korisnicima omogućava dodatni mesec 
              besplatnog korišćenja softvera.  
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default TermsModal;
