import React from "react";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";

function Utilities() {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(path.split("#")[1]);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };

  return (
    <>
      <section className="section-hero" id="start">
        <div className="hero">
          <div className="hero-text-box">
            <h1 className="heading-primary">
              Komunalne delatnosti - kostur SMART CITY koncepta
            </h1>
            <p className="hero-description">
              Komunalne delatnosti su poslovi koje svaka lokalna samouprava mora
              da radi za svoje građane. <br />
              U današnje vreme, kada se sve više govori o pametnim gradovima,
              komunalne delatnosti su kostur SMART CITY filozofije koncepta, jer nema
              Pametnog grada bez dobro organizovanih i kontrolisanih komunalnih
              delatnosti. <br />
              <br />
             Softver za komunalne delatnosti unapredjuje efikasnosti poslovanja kroz automatsku obradu podataka,
             precizan obračun usluga i integraciju sa naplatnim sistemima. Omogućava brže i tačnije fakturisanje korisnicima, 
             praćenje potrošnje i stanje naplate u realnom vremenu. 
              <br />
              <br />
              Uz optimizovano upravljanje resursima, softver smanjuje operativne troškove i poboljšava kvalitet usluga.
              Korisnici dobijaju personalizovane izveštaje i alate za planiranje, što olakšava donošenje strateških odluka i usklađivanje sa zakonskim
              regulativama. 
              Povećana transparetnost i tačnost obračuna rezultira zadovoljnijim korisnicima i bržom naplatom potraživanja
            </p>
          </div>
          <div className="hero-img-box">
            <picture>
              <source srcSet="img/smart-city.png" type="image/png" />
              <img
                src="/poslovni-softver/img/smart-city.png"
                className="hero-img"
                alt="Img of a SmartCity"
              />
            </picture>
          </div>
        </div>
      </section>
      <section className="section-pricing" id="pricing">
        <div className="container">
          <span className="subheading"></span>
          <h3 className="heading-secondary">
            Akord softver za komunalne delatnosti
          </h3>
        </div>

        <div className="container grid grid--2-cols">
          <div className="feature pricing-plan--complete">
            <p className="feature-title">Softver za vodovode</p>
            <p className="feature-text">
              Vodovodi kao javna preduzeća imaju obavezu da vode računa o
              kvalitetu vode, o stanju vodovodne mreže, o potrošnji vode, o
              naplati vode, o održavanju vodovodne mreže, o investicijama u
              vodovodnu mrežu, itd. <br /> Akord softver, pored standarnih
              knjigovodstveni aktivnosti podržava i aktivnosti koje su
              specifične sa preduzeća koja se bave proizvodnjom i distribucikom
              vode i održavanjem kanalizacione mreže. <br /> Uz pomoć Akord
              softvera, moguće je vršiti direkna očitavanja potrošnje snimanjem
              vodomera telefonom ili daljinskim očitavanjem kao najsavremenijim
              načinom praćenja potrošnje. Vrlo je bitan segment i likvidacija
              uplata blagajne koja se vrši automatski i u realnom vremenu bez
              potrebe za dodatnim unosima. Pored ovoga, Akord softver podržava i
              aktivnosti koje su vezane za održavanje vodovodne i kanalizacione
              mreže, kao što su nalozi za intervencije, praćenje intervencija i
              razna izveštavanja koja su potrebna za menadžment ili druge
              korisnike.
            </p>
          </div>
          <div className="feature pricing-plan--complete">
            <p className="feature-title">
              Softver za složena komunalna preduzaća
            </p>
            <p className="feature-text">
              Složena komunalna preduzeća vrše više različitih komunalni usluga
              i obično se javljaju u manjim opštinama. <br /> Akord softver je
              prilagodljiv i može se koristiti za sve komunalne delatnosti što
              je dokazano u više preduzeća u kojima je izvršena implementacija.
              <br /> Uz pomoć Akord softvera, zaposleni i menadžeri mogu da
              obavljaju svoje svakodnevne zadatke i prate sve aktivnosti koje su
              vezane za složena komunalna preduzeća. <br /> Jako je bitno da se
              sve aktivnosti koje se dešavaju u složenim komunalnim preduzećima
              mogu pratiti po vrstama delatnosti, a neophodna izveštavanja su
              prilagođena potrebama korisnika.
              <br />
              Možemo slobodno reći da je Akord softver program za toplane,
              program za distribusiju gasa, program za puteve, program za javnu
              rasvetu, program za zelene površine, program za pijace, program za
              groblja, program za javne parkirališta, program za javne sportske
              hale i druge oblike komunalni preduzeća bez obzira da li su
              delatnosti samostalne ili u okviru složenih komunalnih preduzeća.
            </p>
          </div>

          <button
            onClick={() => handleButtonClick("/#cta")}
            className="btn btn--full margin-right-sm btn--industry"
          >
            Za više informacija <FaInfoCircle className="icon" />
          </button>
        </div>
      </section>
    </>
  );
}

export default Utilities;
