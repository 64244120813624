import React from "react";
import SectionHero from "../sections/SectionHero";
import SectionFeatured from "../sections/SectionFeatured";
import SectionHow from "../sections/SectionHow";
import SectionProducts from "../sections/SectionProducts";
import SectionTestimonials from "../sections/SectionTestimonials";
import SectionPricing from "../sections/SectionPricing";
import SectionCTA from "../sections/SectionCTA";

function Home() {
  return (
    <>
      <SectionHero />
      {/*<SectionFeatured />*/}
      <SectionHow />
      <SectionProducts />
      <SectionTestimonials />
      <SectionPricing />
      <SectionCTA />
    </>
  );
}

export default Home;
