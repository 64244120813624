import React from "react";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";

function SectionProducts() {
  return (
    <section className="section-products" id="usage">
      <div className="container center-text">
        <span className="subheading"></span>
        <h2 className="heading-secondary">Naš softver za Vaš biznis</h2>
      </div>
      <div className="container grid grid--3-cols margin-bottom-md">
        <div className="product">
          <img
            src="/poslovni-softver/img/products/trolley.jpg"
            className="product-img"
            alt="The mega market trolley"
          />
          <div className="product-content">
            <p className="product-title">Trgovina</p>
            <ul className="product-attributes">
              <li className="product-attribute">
                <FaCheck className="icon" />
                Veleprodaje i maloprodaje
              </li>

              <li className="product-attribute">
                <FaCheck className="icon" />
                Integrisano inventarisanje
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Adresno praćenje stanja
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Maloprodajne kase
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Elektronske fakture
              </li>
            </ul>
            <div className="container redirection">
              <Link to="/trading" className="link">
                Više o ovome... &rarr;
              </Link>
            </div>
          </div>
        </div>

        <div className="product">
          <img
            src="/poslovni-softver/img/products/smartcity.svg"
            className="product-img"
            alt="SmartCity"
          />
          <div className="product-content">
            <div className="product-tags"></div>
            <p className="product-title">Komunalni poslovi</p>
            <ul className="product-attributes">
              <li className="product-attribute">
                <FaCheck className="icon" />
                Softver za vodovode
              </li>

              <li className="product-attribute">
                <FaCheck className="icon" />
                Softver za toplane
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Softver za tržnice
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Sofver za parking servise
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Sofver za kombinovana JKP
              </li>
            </ul>
            <div className="container redirection">
              <Link to= "/utilities" className="link">
                Više o ovome... &rarr;
              </Link>
            </div>
          </div>
        </div>
        <div className="product">
          <img
            src="/poslovni-softver/img/products/serviceCrop.jpg"
            className="product-img"
            alt="Service Man"
          />
          <div className="product-content">
            <div className="product-tags"></div>
            <p className="product-title">Servisne delatnosti</p>
            <ul className="product-attributes">
              <li className="product-attribute">
                <FaCheck className="icon" />
                Radni nalozi
              </li>

              <li className="product-attribute">
                <FaCheck className="icon" />
                Trebovanja
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Praćenje stanja magacina
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Predračuni i fakture
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Sintetike dokumenata
              </li>
            </ul>
            <div className="container redirection">
              <Link to="/services" className="link">
                Više o ovome... &rarr;
              </Link>
            </div>
          </div>
        </div>
        <div className="product">
          <img
            src="/poslovni-softver/img/products/accounting.jpg"
            className="product-img"
            alt="Tax and Accounting"
          />
          <div className="product-content">
            <div className="product-tags"></div>
            <p className="product-title">Računovodstvo</p>
            <ul className="product-attributes">
              <li className="product-attribute">
                <FaCheck className="icon" />
                Knjigovodstvene aktivnosti
              </li>

              <li className="product-attribute">
                <FaCheck className="icon" />
                Računovodstveni izveštaji
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Finansijski izveštaji
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Osnovna sredstva
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Obračun plata
              </li>
            </ul>
            <div className="container redirection">
              <Link to="/accounting" className="link">
                Više o ovome... &rarr;
              </Link>
            </div>
          </div>
        </div>
        <div className="product">
          <img
            src="/poslovni-softver/img/products/docManagment.jpg"
            className="product-img"
            alt="Document Managment"
          />
          <div className="product-content">
            <div className="product-tags"></div>
            <p className="product-title">DMS - Elektronski dokumenti </p>
            <ul className="product-attributes">
              <li className="product-attribute">
                <FaCheck className="icon" />
                Elektronska arhiva
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Elektronski delovodnik
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Grupe i podgrupe dokumenata
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Faze dokumenta
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Izveštavanje
              </li>
            </ul>
            <div className="container redirection">
              <Link to="/document-management" className="link">
                Više o ovome... &rarr;
              </Link>
            </div>
          </div>
        </div>
        <div className="product">
          <img
            src="/poslovni-softver/img/products/crm.jpg"
            className="product-img"
            alt="Avocado Salad"
          />
          <div className="product-content">
            <div className="product-tags"></div>
            <p className="product-title">CRM - Odnosi sa kupcima</p>
            <ul className="product-attributes">
              <li className="product-attribute">
                <FaCheck className="icon" />
                Unos vrsta aktivnosti
              </li>

              <li className="product-attribute">
                <FaCheck className="icon" />
                Definisanje vrste partnera
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Opcije podataka
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Unos i izmena kalendara
              </li>
              <li className="product-attribute">
                <FaCheck className="icon" />
                Dodatni podaci aktivnosti
              </li>
            </ul>
            <div className="container redirection">
              <Link to="/crm" className="link">
                Više o ovome... &rarr;
              </Link>
            </div>
          </div>
        </div>
      </div>
      _
    </section>
  );
}

export default SectionProducts;
