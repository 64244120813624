import React from "react";

function SectionHow() {
  return (
    <section className="section-how" id="aboutus">
      <div className="container">
        <span className="subheading"></span>
        <h2 className="heading-secondary">
          Vaši podaci u oblacima - na sigurnom mestu
        </h2>
        <div className="container-video">
          <video autoPlay muted loop>
            <source src="/poslovni-softver/video/servers1020.mp4" type="video/mp4" />
            Vaš pregledač ne podržava video klipove.
          </video>
        </div>
        <div className="row">
          <div className="col-1-of-4">
            <div className="feature-box">
              <h3 className="heading-tertiary u-margin-bottom-small">
                Istorija poslovanje duža od 15 godina
              </h3>
              <p className="feature-box__text">
                Razvoj Akord softvera je počeo 2007. godine sa prvom ERP
                verzijom razvijenom na klijen-server arhitekturi. Od 2013 godine
                počeo je razvoj verzija za online knjigovodstvo u Klaud
                tehnologijama koje su brzo postala vodeća rešenja u oblasti
                poslovnog softvera.
              </p>
            </div>
          </div>

          <div className="col-1-of-4">
            <div className="feature-box">
              <h3 className="heading-tertiary u-margin-bottom-small">
                Klaud tehnologija (Cloud Technology)
              </h3>
              <p className="feature-box__text">
                ERP i CRM softveri danas kao najzastupljenije verzije u
                poslovnim aplikacijama sa garantovanim pristupom 24/7 svuda gde
                ima internet. Sa ovim je bezbednost podataka na prvom mestu bez
                obzira da li je u pitanju program za kasu ili ERP softver.
              </p>
            </div>
          </div>

          <div className="col-1-of-4">
            <div className="feature-box">
              <h3 className="heading-tertiary u-margin-bottom-small">
                Filozofija - prvo korisnik
              </h3>
              <p className="feature-box__text">
                Osnovna filozofija razvoja je uvek bila - potrebe korisnika na
                prvom mestu. Tako se korisnici osećaju prijatno u rešavanju
                svojih obaveza bez obzira da li program koriste za
                knjigovodstvo, računovodstvo, veleprodaju, maloprodaju ili rade
                elektronsko arhiviranje dokumenata u delovodnik.
              </p>
            </div>
          </div>

          <div className="col-1-of-4">
            <div className="feature-box">
              <h3 className="heading-tertiary u-margin-bottom-small">
                Fokus na novim tehnologijama
              </h3>
              <p className="feature-box__text">
                Softver je naš zanat i mi ga razvijamo posvećeni ulaganjem u
                istraživanje i razvoj. Radimo da budemo ispred drugih kada su u
                pitanju tehnologije i inovativnost. Ponosni smo na konstantan
                razvoj tokom godina koji je odgovor na zahteve naših korisnika i
                verujemo da smo dostigli visok i prepoznatljiv kvalitet.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionHow;
