import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import DropdownPrice from "./DropdownPrice";
import DropdownUsage from "./DropdownUsage"; // Importing DropdownUsage
import { FaBars, FaKey, FaTimes, FaCaretDown } from "react-icons/fa"; // Importing icons from react-icons

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdownPrice, setDropdownPrice] = useState(false);
  const [dropdownUsage, setDropdownUsage] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(null); // Track which submenu is open
  const location = useLocation();

  const closeMobileMenu = useCallback(() => {
    setClick(false);
    setDropdownPrice(false);
    setDropdownUsage(false);
    setSubmenuOpen(null);
  }, []);

  const handleClick = () => {
    setClick(!click);
    setDropdownPrice(false);
    setDropdownUsage(false);
    setSubmenuOpen(null);
  };

  const onMouseEnter = (dropdownSetter) => {
    if (window.innerWidth >= 960) {
      dropdownSetter(true);
    }
  };

  const onMouseLeave = (dropdownSetter) => {
    if (window.innerWidth >= 960) {
      dropdownSetter(false);
    }
  };

  const handleDropdownClick = (e, dropdownSetter, dropdownName) => {
    if (window.innerWidth < 960) {
      e.preventDefault();
      if (submenuOpen === dropdownName) {
        dropdownSetter(false);
        setSubmenuOpen(null);
        window.location.href = e.currentTarget.href; // Navigate to the main menu item
      } else {
        setDropdownPrice(false);
        setDropdownUsage(false);
        dropdownSetter(true);
        setSubmenuOpen(dropdownName);
      }
    } else {
      scrollToTop();
    }
  };

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    closeMobileMenu();
  }, [closeMobileMenu]);

  const scrollToSection = useCallback(
    (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        closeMobileMenu();
      }
    },
    [closeMobileMenu]
  );

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.substring(1);
      scrollToSection(id);
    } else {
      scrollToTop();
    }
  }, [location, scrollToSection, scrollToTop]);

  return (
    <>
      <nav className="navbar" id="navbar">
        <Link to="/" className="navbar-logo" onClick={scrollToTop}>
          <img className="logo" alt="akord logo" src="/poslovni-softver/img/logo64x64.png" />
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          {click ? <FaTimes className="icon" /> : <FaBars className="icon" />}
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li
            className={`nav-item ${location.pathname === "/" ? "active" : ""}`}
          >
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              Početna
            </Link>
          </li>
          <li
            className={`nav-item ${
              location.hash === "#aboutus" ? "active" : ""
            }`}
          >
            <Link
              to="/#aboutus"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              O nama
            </Link>
          </li>
          <li
            className={`nav-item ${location.hash === "#usage" ? "active" : ""}`}
            onMouseEnter={() => onMouseEnter(setDropdownUsage)}
            onMouseLeave={() => onMouseLeave(setDropdownUsage)}
          >
            <a
              href="/poslovni-softver#usage"
              className="nav-links"
              onClick={(e) => handleDropdownClick(e, setDropdownUsage, "usage")}
            >
              Primena softvera
              <span className="space-left">
                <FaCaretDown className="icon" />
              </span>
            </a>
            {dropdownUsage && (
              <DropdownUsage closeMobileMenu={closeMobileMenu} />
            )}
          </li>
          <li
            className={`nav-item ${
              location.hash === "#pricing" ? "active" : ""
            }`}
            onMouseEnter={() => onMouseEnter(setDropdownPrice)}
            onMouseLeave={() => onMouseLeave(setDropdownPrice)}
          >
            <a
              href="/poslovni-softver#pricing"
              className="nav-links"
              onClick={(e) => handleDropdownClick(e, setDropdownPrice, "price")}
            >
              Verzije softvera{" "}
              <span className="space-left">
                <FaCaretDown className="icon" />
              </span>
            </a>
            {dropdownPrice && (
              <DropdownPrice closeMobileMenu={closeMobileMenu} />
            )}
          </li>
          <li
            className={`nav-item ${
              location.hash === "#testimonials" ? "active" : ""
            }`}
          >
            <Link
              to="/#testimonials"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Impresije korisnika
            </Link>
          </li>
          <li
            className={`nav-item ${location.hash === "#cta" ? "active" : ""}`}
          >
            <Link to="/#cta" className="nav-links" onClick={closeMobileMenu}>
              Kontakt
            </Link>
          </li>
          <li
            className={`nav-item ${
              location.pathname === "/sign-up" ? "active" : ""
            }`}
          >
            <Link
              to="/login"
              className="main-nav-link nav-cta"
              onClick={closeMobileMenu}
            >
              Akord korisnici <FaKey className="icon" />
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
