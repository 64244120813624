import React from "react";
import JobDetails from "../pages/JobDetails";
import { useParams } from "react-router-dom";

const jobData = {
  1: {
    title: "Konsultant za knjigovodstveno-računovodstvene poslove",
    description: [
      "Završena viša škola ili fakultet ekonomskog smera.",
      "Iskustvo u izradi završni računa.",
      "Dobro poznavanje knjigovodstveno-računovodstveni poslova.",
      "Dobro poznavanje Microsoft Excel-a.",
      "Poželjno iskustvo u konsultantskim poslovima.",
    ],
    details: [
      { label: "Radno vreme", value: "Puno radno vreme" },
      { label: "Radno iskustvo", value: "3 godine" },
      { label: "Obrazovanje", value: "VSS ili VS" },
      { label: "Plata", value: "Po dogovoru." },
      { label: "Mesto Rada", value: "Novi Sad" },
    ],
  },
  2: {
    title: "Konsultant za procese u robno-materijalnom knjigovodstvu",
    description: [
      "Završena srednja škola ili fakultet.",
      "Dobro poznavanje poslovne dokumentacije vezane za robne tokove.",
      "Iskustvo u radu sa robom u veleprodaji ili maloprodaji.",
      "Dobro poznavanje Microsoft Excel-a.",
      "Poželjno iskustvo u konsultantskim poslovima.",
      "Poželjno osnovno poznavanje SQL jezika.",
    ],
    details: [
      { label: "Radno vreme", value: "Puno radno vreme" },
      { label: "Iskustvo", value: "3 godine minimalno" },
      { label: "Obrazovanje", value: "SSS, VSS, VS" },
      { label: "Plata", value: "Po dogovoru" },
      { label: "Mesto Rada", value: "Novi Sad" },
    ],
  },
  3: {
    title: "Konsultant za procese obračuna plata",
    description: [
      "Završena viša škola ili fakultet ekonomskog smera.",
      "Iskustvo u obračunu plata u firmama sa 50 i više zaposleni.",
      "Dobro poznavanje knjigovodstveno-računovodstveni poslova.",
      "Dobro poznavanje Microsoft Excel-a.",
      "Poželjno iskustvo u konsultantskim poslovima.",
    ],
    details: [
      { label: "Radno vreme", value: "Puno radno vreme" },
      { label: "Iskustvo", value: "3 godine" },
      { label: "Obrazovanje", value: "VSS, VS" },
      { label: "Plata", value: "Po dogovoru" },
      { label: "Mesto Rada", value: "Novi Sad" },
    ],
  },
  4: {
    title: "Administrator dokumenata u kompaniji",
    description: [
      "Na ovom radnom mestu se rade administivni poslovi kao što je:",
      "- prijem i otprema pošte",
      "- vođenje evidencije o dokumentima",
      "- priprema dokumenata za arhiviranje",
      "- javljanje na telefon",
      "- kontaktiranje klijenata koji su se registrovali na sajtu",
      "- unos detalja o klijentima u bazu podataka",
      "- pomoć u organizaciji sastanaka",
      "- pomoć u organizaciji seminara",
      "- pomoć u organizaciji obuka",
      "- pomoć u organizaciji team buildinga",
    ],
    details: [
      { label: "Radno vreme", value: "Puno radno vreme" },
      { label: "Iskustvo", value: "Nije neophodno" },
      { label: "Obrazovanje", value: "Osnovno ili srednje obrazovanje." },
      { label: "Plata", value: "Od 55.000 do 65.000" },
      { label: "Mesto Rada", value: "Novi Sad" },
    ],
  },
};

export default function Job() {
  const { id } = useParams();
  const job = jobData[id];

  if (!job) {
    return <div>Job not found</div>;
  }

  return (
    <>
      <div className="job-page">
        <JobDetails
          title={job.title}
          description={job.description}
          details={job.details}
        />
      </div>
    </>
  );
}
