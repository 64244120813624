import React from "react";
import { useNavigate } from "react-router-dom";
import { FaPenNib, FaCoins, FaFilm } from "react-icons/fa";
import VideoPlayer from "../VideoPlayer";

function Start() {
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState("");

  const handleButtonClick = (path) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(path.split("#")[1]);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };

  const handleVideoClick = (url) => {
    setVideoUrl(url);
    setShowVideo(true);
  };

  const handleVideoClose = () => {
    setShowVideo(false);
    setVideoUrl("");
  };

  return (
    <>
      <section className="section-hero" id="start">
        <div className="hero">
          <div className="hero-text-box">
            <h1 className="heading-primary">
              Akord Start - jednostavan i povoljan softver za fakturisanje
            </h1>
            <p className="hero-description">
              Podržavamo odvažne i hrabre osobe koje su donele odluku da uzmu
              sudbinu u svoje ruke. Na početku svakog posla bitna je odvažnost,
              novac i podrška. <br />
              Naš doprinos njihovom uspehu je jednostavan i finansijski vrlo
              pristupačan softver za fakturisanje i praćenje kupaca sa uslugom
              čuvanja podataka i dostupnosti sa svakog mesta i u svako vreme.
            </p>
            <button
              onClick={() => handleButtonClick("/#cta")}
              className="btn btn--outline-white margin-right-sm"
            >
              Postanite korisnik <FaPenNib className="icon" />
            </button>

            <button
              onClick={() => handleButtonClick("/#pricing")}
              className="btn btn--outline-white"
            >
              Cena licence <FaCoins className="icon" />
            </button>
          </div>
          <div className="hero-img-box">
            <picture>
              <source srcSet="img/start512.png" type="image/png" />
              <img
                src="/poslovni-softver/img/start1200.png"
                className="hero-img"
                alt="Entrepreneur with a scale"
              />
            </picture>
          </div>
        </div>
      </section>
      <section className="section-how" id="aboutus">
        <div className="container">
          <span className="subheading"></span>
          <h2 className="heading-secondary">
            Fakturisanje i praćenje kupaca iz oblaka
          </h2>
          <div className="container-video">
            <video autoPlay muted loop>
              <source src="/poslovni-softver/video/servers1020.mp4" type="video/mp4" />
              Vaš pregledač ne podržava video klipove.
            </video>
          </div>
          <div className="row">
            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-world"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Brzina i dostupnost podataka
                </h3>
                <p className="feature-box__text">
                  Nove tehnologije za novo vreme! <br />
                  <br />
                  Kada počnete fakturisati u hodu i pratiti naplatu i plaćanje u
                  realnom vremnu znaćete da ste ispred konkurencije.
                  <br />
                  Jednom otvoren artikal i jednom otvoren kupac su u sistemu
                  zauvek pa će svaki sledeći put biti brže i lakše. Možete
                  koristiti naše podešavanje za izgled računa ili ubaciti svoj
                  logo. <br />
                  <br />
                  Naša video uputstva su tu da Vam pomognu.
                </p>
              </div>
            </div>

            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-compass"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Unos kupaca i dobavljača
                </h3>
                <p className="feature-box__text">
                  Unos kupca i dobavljača je jednostavan i brz. <br />
                  <br />
                  Jednom uneti podaci su u sistemu zauvek i biraju se pretragom
                  po nazivu, delu naziva, mestu, PIB-u ili matičnom broju.{" "}
                  <br /> Ovo je neophodan korak da bi mogli fakturisati i
                  pratiti naplatu i plaćanje. <br />
                  Vaše poslovne partnere možetete povući i iz baze Narodne banke
                  Srbije tako ćete biti sigurni u tačnost podataka.
                </p>
                <div className="plan-sing-up">
                  <button
                    onClick={() =>
                      handleVideoClick("https://www.youtube.com/watch?v=ASj0jIJUErM")
                    }
                    className="btn btn--outline-white"
                  >
                    Uputstvo...
                    <FaFilm className="icon" />
                  </button>
                </div>
              </div>
            </div>

            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-map"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Unos artikala
                </h3>
                <p className="feature-box__text">
                  Unos artikala je drugi zahtev sistema. <br />
                  <br />
                  Unosom artikala dobijate mogućnost da ih fakturišete kupcima
                  koje ste takođe uneli u sistem. <br />
                  Trebate uneti bar jedan artikal da bi mogli fakturisati.
                  <br /> Artikle možete unositi pre ili posle unosa kupca.
                  <br />
                  Uneti artikli se biraju pretragom po nazivu, delu naziva,
                  šifri, barkodu ili grupi. Unos će vrlo brzo postati rutina.
                </p>

                <div className="plan-sing-up">
                  <button
                    onClick={() => handleVideoClick("https://www.youtube.com/watch?v=zZtjAipYLhE")}
                    className="btn btn--outline-white"
                  >
                    Uputstvo...
                    <FaFilm className="icon" />
                  </button>
                </div>
              </div>
            </div>

            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-heart"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Proces fakturisanja
                </h3>
                <p className="feature-box__text">
                  Faktura se kreira u nekoliko koraka. <br />
                  <br />
                  Kada unesete u sistem kupca i sve artikle koji će biti na
                  računu, faktura se kreira u nekoliko koraka. <br />
                  Prvo se bira kupac, zatim se bira jedan ili više artikala,
                  količine, unose cene i na kraju se unosi rabat ukoliko ga ima.
                  <br />
                  Ovo možete raditi na ponudi, predračunu ili računu jer Akord
                  Start nudi sve tri mogućnosti.
                  <br />
                </p>
                <div className="plan-sing-up">
                  <button
                    onClick={() => handleVideoClick("https://www.youtube.com/watch?v=6AXfCc0filc")}
                    className="btn btn--outline-white"
                  >
                    Uputstvo...
                    <FaFilm className="icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showVideo && <VideoPlayer url={videoUrl} onClose={handleVideoClose} />}
    </>
  );
}

export default Start;
