import React from "react";
import "./PopUpModal.css"; // Import the CSS for the modal

const PopUpModal = ({ message, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="popupModal">
        <div className="popupModal-header">
          <h2>Akord poruka</h2>
          <button className="modal-close" onClick={onClose}>
            ×
          </button>
        </div>
        <div className="popupModal-content">
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default PopUpModal;
