import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import LoginForm from "../elements/LoginForm";
import Services from "../pages/Services";
import AkordERP from "../pages/AkordERP";
import AkordCRM from "../pages/AkordCRM";
import AkordDMS from "../pages/AkordDMS";
import Micro from "../pages/Micro";
import Accounting from "../pages/Accounting";
import Basic from "../pages/Basic";
import Start from "../pages/Start";
import Trading from "../pages/Trading";
import Utilities from "../pages/Utilities";
import Recruitment from "../pages/Recruitment";
import Job from "../pages/Job";
import ScrollToTop from "./ScrollToTopGlobal";

function Main() {
  return (
    <main>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/services" element={<Services />} />
        <Route path="/erp" element={<AkordERP />} />
        <Route path="/document-management" element={<AkordDMS />} />
        <Route path="/crm" element={<AkordCRM />} />
        <Route path="/accounting" element={<Accounting />} />
        <Route path="/trading" element={<Trading />} />
        <Route path="/utilities" element={<Utilities />} />
        <Route path="/micro" element={<Micro />} />
        <Route path="/basic" element={<Basic />} />
        <Route path="/start" element={<Start />} />
        <Route path="/recruitment" element={<Recruitment />} />
        <Route path="/job/:id" element={<Job />} />
      </Routes>
    </main>
  );
}

export default Main;
