import React from "react";
import { FaCheck, FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function SectionPricing() {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(path.split("#")[1]);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };
  return (
    <section className="section-pricing" id="pricing">
      <div className="container">
        <span className="subheading"></span>
        <h2 className="heading-secondary">
          Cene licenci
        </h2>
      </div>

      <div className="container grid grid--3-cols margin-bottom-md">
        <div className="pricing-plan pricing-plan--complete">
          <header className="plan-header">
            <p className="plan-name">Akord Start</p>
            <p className="plan-price">
              <span>RSD</span>700
            </p>
            <p className="plan-text">
              <strong>mesečno</strong>
            </p>
            <div className="empty-div">
          
            </div>
            
          </header>
          <ul className="list">
            <li className="list-item">
              <FaCheck className="icon" />
              Evidentiranje kupaca
            </li>
            <li className="list-item">
              <FaCheck className="icon" />
              <span>Evidentiranje dobavljača</span>
            </li>
            <li className="list-item">
              <FaCheck className="icon" />
              <span>Izrada predračuna</span>
            </li>
            <li className="list-item">
              <FaCheck className="icon" />
              <span>Izrada računa</span>
            </li>
            <li className="list-item">
              <FaCheck className="icon" />
              <span>Evidencija uplata i plaćanja</span>
            </li>
          </ul>
          <div className="plan-sing-up">
            <button
              onClick={() => handleButtonClick("/start")}
              className="btn btn--outline-white"
            >
              Više o proizvodu...
              <FaSignOutAlt className="icon" />
            </button>
          </div>
        </div>
        <div className="pricing-plan pricing-plan--complete">
          <header className="plan-header">
            <p className="plan-name">Akord Micro</p>
            <p className="plan-price">
              <span>RSD</span>2900
            </p>
            <p className="plan-text">mesečno za prvu licencu</p>
            <p className="plan-price">
              <span>RSD</span>1450
            </p>
            <p className="plan-text">za ostale</p>
          </header>
          <ul className="list">
            <li className="list-item">
              <FaCheck className="icon" />
              <span>
                <strong>Robno-materijalno knjigovodstvo</strong>
              </span>
            </li>
            <li className="list-item">
              <FaCheck className="icon" />
              <span>
                <strong>Veleprodaje i maloprodaje</strong>
              </span>
            </li>
            <li className="list-item">
              <FaCheck className="icon" />
              <span>
                <strong>Praćenje zaliha</strong>
              </span>
            </li>
            <li className="list-item">
              <FaCheck className="icon" />
              <span>
                <strong>Uslužne delatnosti</strong>
              </span>
            </li>
            <li className="list-item">
              <FaCheck className="icon" />
              <span>
                <strong>Standarni izveštaji</strong>
              </span>
            </li>
          </ul>
          <div className="plan-sing-up">
            <button
              onClick={() => handleButtonClick("/micro")}
              className="btn btn--outline-white"
            >
              Više o proizvodu...
              <FaSignOutAlt className="icon" />
            </button>
          </div>
        </div>
        <div className="pricing-plan pricing-plan--complete">
          <header className="plan-header">
            <p className="plan-name">Akord Basic</p>
            <p className="plan-price">
              <span>RSD</span>3600
            </p>
            <p className="plan-text">mesečno za prvu licencu</p>
            <p className="plan-price">
              <span>RSD</span>1800
            </p>
            <p className="plan-text">za ostale</p>
          </header>
          <ul className="list">
            <li className="list-item">
              <FaCheck className="icon" />
              <span>
                <strong>Robno-materijalno knjigovodstvo</strong>
              </span>
            </li>
            <li className="list-item">
              <FaCheck className="icon" />
              <span>
                <strong>Računovodstvo i finansije</strong>
              </span>
            </li>
            <li className="list-item">
              <FaCheck className="icon" />
              <span>
                <strong>Inventarisanje</strong>
              </span>
            </li>
            <li className="list-item">
              <FaCheck className="icon" />
              <span>
                <strong>Amortizacija</strong>
              </span>
            </li>
            <li className="list-item">
              <FaCheck className="icon" />
              <span>
                <strong>Obračun plata</strong>
              </span>
            </li>
          </ul>
          <div className="plan-sing-up">
            <button
              onClick={() => handleButtonClick("/basic")}
              className="btn btn--outline-white"
            >
              Više o proizvodu...
              <FaSignOutAlt className="icon" />
            </button>
          </div>
          <div>
        </div>
        </div>
      </div>

      <div className="pdv-cene">
          Sve cene su bez PDV-a
          </div>
      <div className="container grid grid--2-cols">
        <div className="feature pricing-plan--complete">
          <p className="feature-title">Akord ERP Softver</p>
          <p className="feature-text">
            Akord ERP je zasnovan na Basic verziji sa fokusom na specifične
            potrebe klijenata. Trenutno ga koriste komunalna preduzeća i veće
            privatne firme kojima treba softver za veleprodaje i maloprodaje i
            sa kojima se prave posebni ugovori po pitanju migracije podataka,
            licenciranja, obuka korisnika i hostovanja podataka na godišnjem
            nivou.
          </p>
        </div>
        <div className="feature pricing-plan--complete">
          <p className="feature-title">Čuvanje podataka velikog obima</p>
          <p className="feature-text">
            Za sve naše kupce podrazumevano je da čuvamo njihova dokumenta do{" "}
            <strong>5 GB</strong> veličine. Ukoliko se to premaši, po zahtevu
            klijenata, brišemo dokumenta koja su zastarela ili se prave posebni
            ugovori za trajno čuvanje podataka velikog obima. Svi podaci se
            čuvaju na Azure serverima koji pripadaju kompaniji Microsoft.
          </p>
        </div>
      </div>
    </section>
  );
}

export default SectionPricing;
