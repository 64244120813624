import React from "react";
import { useNavigate } from "react-router-dom";
import { FaPenNib, FaCoins } from "react-icons/fa";

export default function Basic() {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(path.split("#")[1]);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };

  return (
    <>
      <section className="section-hero" id="start">
        <div className="hero">
          <div className="hero-text-box">
            <h1 className="heading-primary">Akord Basic - web ERP softver</h1>
            <p className="hero-description">
              Onda kada firma dostigne neophodnost internog knjigovodstva, Akord
              Basic je pravi poslovni program. <br />
              Razvijan sa ciljem da bude jednostavan za korišćenje, a da pritom
              pruži sve neophodne funkcionalnosti za praćenje poslovanja i
              izveštavanje. Jednom unet podatak je dostupan svuda i u svakom
              trenutku. <br />
              Veliki broj korisnika koji je prešao sa drugih programa na Akord
              Basic zadovoljni su jer je ovo i program za obračun zarada,
              amortizaciju osnovnih sredstava i još mnogo drugih funkcionalnosti.
              <br />
              Softver je u potpunosti razvijen u Srbiji i prilagođen za rad u
              našim uslovima.
            </p>
            <button
              onClick={() => handleButtonClick("/#cta")}
              className="btn btn--outline-white margin-right-sm"
            >
              Postanite korisnik <FaPenNib className="icon" />
            </button>
            <button
              onClick={() => handleButtonClick("/#pricing")}
              className="btn btn--outline-white"
            >
              Cena licence <FaCoins className="icon" />
            </button>
          </div>
          <div className="hero-img-box">
            <picture>
              <source srcSet="img/basic512.png" type="image/png" />
              <img
                src="/poslovni-softver/img/basic1200.png"
                className="hero-img"
                alt="Blue graph with business people"
              />
            </picture>
          </div>
        </div>
      </section>
      <section className="section-how" id="aboutus">
        <div className="container">
          <span className="subheading"></span>
          <h2 className="heading-secondary">Akord Basic - više od softvera</h2>
          <div className="container-video">
            <video autoPlay muted loop>
              <source src="/poslovni-softver/video/servers1020.mp4" type="video/mp4" />
              Vaš pregledač ne podržava video klipove.
            </video>
          </div>
          <div className="row">
            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-world"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Sigurnost podataka
                </h3>
                <p className="feature-box__text">
                  Sigurnost podataka naši klijenata uvek je bila na prvom mestu!
                  <br />
                  <br /> Podaci se čuvaju na Azure serverima u Nemačkoj, a
                  pristup je omogućen samo korisnicima sa pravom pristupa.
                  <br />
                  Ugovor sa Microsoft-om garantuje sigurnost podataka i to je
                  bila politika od prvog dana.
                  <br />
                  <br />
                  Microsoft ima ugovor o zaštiti podataka sa EU i sa Srbijom
                  posebno, a to je garancija da su podaci sigurni i da se neće
                  koristiti u druge svrhe.
                  <br />
                  <br />
                  Verujemo da je to najbolji način da se obezbedi sigurnost i
                  privatnost podataka.
                </p>
              </div>
            </div>

            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-compass"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Dostupnost
                </h3>
                <p className="feature-box__text">
                  Uvek, uz internet konekciju možete pristupiti vašim podacima i
                  raditi u programu. <br />
                  Pristup je omogućen sa svih uređaja, telefona, tableta, laptop
                  ili desktop računara.
                  <br />
                  <br />
                  Veštiji korisnici će povezati telefon i laptom i raditi u
                  prirodi ili na terenu jer je server na internetu i uvek
                  dostupan.
                  <br />
                  <br />
                  Program se može koristiti i kod dislocirani korisnika kao što
                  su maloprodajni objekti ili pomoćna skladišta.
                  <br /> <br /> Poslovni softver je razvijan i prilagođen za rad
                  u oblaku, a ta vrsta proslovanja je postala standard.
                </p>
              </div>
            </div>

            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-map"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Uštede u poslovanju
                </h3>
                <p className="feature-box__text">
                  Mnogi i danas troše ozbiljna sredstva na održavanje servera i
                  mreža i na plate IT osoblja. <br />
                  Svaki problem sa serverom ili mrežom znači gubitak vremena i
                  novca. <br />
                  <br />
                  Potrošnja električne energije i klimatizacije servera su
                  takođe značajni troškovi na koje se obično ne obraća pažnja
                  jer je osećaj da je to neophodno.
                  <br />
                  <br />
                  Sve je ovo moguće izbeći korišćenjem softvera u oblaku jer sve
                  te troškove snosi Akord kao pružalac usluge, a vi bezbižno
                  koristite program i plaćate mesečne licence.
                </p>
              </div>
            </div>

            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-heart"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Konstantan razvoj
                </h3>
                <p className="feature-box__text">
                  Podržani su svi prodajni dokumeti: ponuda, profaktura,
                  faktura, maloprodajni račun, izvozni račun, povratnica i
                  naravno, storniranje svih dokumenata po potrebi. <br /> <br />
                  Softver podržava rad sa gotovinskim i avansnim računima, sa i
                  bez PDV-a, više valuta, ali i evidentiranje uplata i plaćanja
                  i njihovo povezivanje. <br /> <br />
                  Osnovni izveštaji su tu da prate poslovanje kao što su
                  sintetike dokumenata, pregled kupaca i dobavljača, a posebni
                  izveštaji su tu da prate zakonske obaveze kod specifični
                  delatnosti.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
