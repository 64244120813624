import React from "react";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";

function Services() {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(path.split("#")[1]);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };

  return (
    <>
      <section className="section-hero" id="start">
        <div className="hero">
          <div className="hero-text-box">
            <h1 className="heading-primary">Servisna delatnost</h1>
            <p className="hero-description">
              Servisiranje i održavanje vozila, mašina i opreme je široko
              zastupljena delatnost i obuhvata različite vrste aktivnosti.{" "}
              <br /> <br />
              Akord softver je implementiran u više servisnih radionica raznih
              vrsta i veličina i prilagodljiv je za praktično sve vrste
              servisnih delatnosti. <br />
              Standardna dokumenta koja se koriste u servisnoj delatnosti su:
              radni nalog, otpremnica, fakture, izveštaji o radu, izveštaji o
              zalihama, izveštaji o radnim satima, izveštaji o zavisnim
              troškovima, izveštaji o servisnim intervencijama, izveštaji o
              garancijama, izveštaji o servisnim ugovorima i druge vrste
              izveštaja koji su potrebni za menadžment ili druge korisnike.
              <br />
              <br />
              Pored svih ovih izveštaja, Akord softver je moguće konfigurisati i
              za specifične izveštaje koji su potrebni korisnicima.
            </p>
          </div>
          <div className="hero-img-box">
            <picture>
              <source srcSet="img/products/serviceCrop.jpg" type="image/jpg" />
              <img
                src="/poslovni-softver/img/products/serviceCrop.jpg"
                className="hero-img"
                alt="Img of a serviceman"
              />
            </picture>
          </div>
        </div>
      </section>
      <section className="section-pricing" id="pricing">
        <div className="container">
          <span className="subheading"></span>
          <h3 className="heading-secondary">Najčešće servisne delatnosti</h3>
        </div>

        <div className="container grid grid--2-cols">
          <div className="feature pricing-plan--complete">
            <p className="feature-title">Auto servisi</p>
            <p className="feature-text">
              Auto servisi su sinonim za servisnu delatnost. Akord softver se
              koristi u mnogim auto servisima raznih vrsta i veličina, od
              generalni uvoznika do malih servisni radionica. <br /> Kao i svaka
              branša i auto servisi imaju svoje specifičnosti koje su podržane u
              Akord softveru i koje su bile razvijane u saradnji sa korisnicima.{" "}
              <br />
              Specifičnosti kao što su praćenja vremena po operacijama su
              prihvaćena i u drugim delatnostima kada su im predložene od strane
              konsultanata koji su radili na implementaciji u drugim
              delatnostima.
            </p>
          </div>
          <div className="feature pricing-plan--complete">
            <p className="feature-title">
              Održavanje mašina i opreme u poljoprivredi
            </p>
            <p className="feature-text">
              Delatnosti održavanja vezane za poljoprivredu su neophodne u ovoj
              oblasti privrede.
              <br /> Bez obzira da li se radi o održavanju pumpi, sistema za
              navodnjavanje, traktora, kombajna, prikolica, motokultivatora,
              kosilica, prskalica, sejalica, plugova, tanjirača, kultivatora,
              freza i drugih mašina i opreme, neophodno je praćenje tih
              aktivnosti kroz poslovni softver. <br />
              Praćenje zaliha delova, radnih naloga, radnih sati, servisnih
              intervencija i mnogi drugi su nezamislive bez poslovnog softvera.{" "}
              <br />
            </p>
          </div>
          <button
            onClick={() => handleButtonClick("/#cta")}
            className="btn btn--full margin-right-sm btn--industry"
          >
            Za više informacija <FaInfoCircle className="icon" />
          </button>
        </div>
      </section>
    </>
  );
}

export default Services;
