import React from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import TermsModal from "../elements/TermsModal";
import { Link } from "react-router-dom"; 

function Footer() {
  const [showTermsModal, setShowTermsModal] = React.useState(false);

  const openTermsModal = () => {
    setShowTermsModal(true);
  };

  const closeTermsModal = () => {
    setShowTermsModal(false);
  };

  return (
    <footer className="footer" id="foot">
      <div className="container grid grid--footer">
        <div className="logo-col">
          <a href="/poslovni-softver#hero" className="logo">
            <img className="logo" alt="akord logo" src="/poslovni-softver/img/logo64x64.png" />
          </a>
          <ul className="social-links">
          <li>
              <a
                className="footer-link"
                href="https://www.facebook.com/PoslovniSoftverAkord"
                target="_blank"
              >
                <FaFacebook className="icon" name="logo-facebook"></FaFacebook>
              </a>
            </li>
            {<li>
              <a className="footer-link" 
                href="https://www.youtube.com/@akordsoftverd.o.o.2420"
                target="_blank"
                >
                <FaYoutube
                  className="icon"
                  name="logo-youtube"
                ></FaYoutube>
              </a>
            </li> }
          
            {/* <li>
              <a className="footer-link" href="#">
                <FaTwitter className="icon" name="logo-twitter"></FaTwitter>
              </a>
            </li> */}
          </ul>
        </div>

        <div className="address-col">
          <p className="footer-heading">Kontakt podaci</p>
          <address className="contacts">
            <p className="address">Srbija, Novi Sad, Pere Popadića 7</p>
            <p>
              <a className="footer-link" href="tel:415-201-6370">
                +381 (0) 21 6412-000
              </a>
              <br />
              <a className="footer-link" href="mailto:office@akord.rs">
                office@akord.rs
              </a>
            </p>
          </address>
        </div>
        <nav className="footer-nav-col">
          <p className="footer-heading">Korisnici</p>
          <ul className="footer-nav">
            <li>
            <Link className="footer-link" to="/login">
              Registracija
            </Link>
            </li>
            <li>
            <Link className="footer-link" to="/login">
              Logovanje
            </Link>
            </li>
            <li>
              <a className="footer-link" href="#" onClick={openTermsModal}>
                Uslovi korišćenja
              </a>
            </li>
          </ul>
        </nav>

        <nav className="nav-col">
          <p className="footer-heading">Ada Soft d.o.o.</p>
          <ul className="footer-nav">
             <Link className="footer-link" to="/#aboutus">
              O nama
            </Link>
            <li>
              <Link className="footer-link" to="/recruitment">
                Posao
              </Link>
            </li>
            {/* <li>
              <a className="footer-link" href="/#featured">
                Press
              </a>
            </li> */}
          </ul>
        </nav>
      </div>
      <p className="copyright">
        Copyright &copy; <span className="year">2024</span> Ada Soft d.o.o.
        <br /> Sva prava zaštićena.
      </p>
      {showTermsModal && <TermsModal onClose={closeTermsModal} />}
    </footer>
  );
}

export default Footer;
