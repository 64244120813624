import React from "react";
import ReactPlayer from "react-player";
import { FaTimesCircle } from "react-icons/fa"; // Import the close icon

const VideoPlayer = ({ url, onClose }) => {
  return (
    <div className="video-player-overlay">
      <div className="video-player-container">
        <button className="video-player-close" onClick={onClose}>
          <FaTimesCircle />
        </button>
        <ReactPlayer url={url} controls width="100%" height="100%" />
      </div>
    </div>
  );
};

export default VideoPlayer;
