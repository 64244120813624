import React, { useEffect, useState} from "react";
import "./LoginForm.css";
import { FaUser, FaLock, FaEnvelope, FaHouseUser, FaPhone } from "react-icons/fa";
import FirmSelection from "./FirmSelection"; 
import axios from 'axios';
import ErrorModal from "./ErrorModal";
import TermsModal from "./TermsModal";
import PopUpModal from "./PopUpModal";

function LoginForm() {

  const API_URL = process.env.NODE_ENV === 'production'  ? 'https://akord.rs/api' : 'http://localhost:15510/api';

  //login
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginDto, setLoginDto] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showFirmSelection, setShowFirmSelection] = useState(true);
  const [firmSelected, setFirmSelected] = useState(false);
  const [loggedFirm, setLoggedFirm] = useState("");

  

  //registracija
  const [userFullName, setUserFullName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [companyPib, setCompanyPib] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyLocation, setCompanyLocation] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyActivity, setCompanyActivity] = useState("");
  const [companyEmployeeNum, setCompanyEmployeeNum] = useState("");
  const [additionalNote, setAdditionalNote] = useState("");
  const [errorLog, setErrorLog] = useState(null);
  const [isInvalidPib, setIsInvalidPib] = useState(false);
  const [modalPopUp, setModalPopUp] = useState(null);

  //firme
  
  const [firmeList, setFirmeList] = useState([]);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);


  const handleCheckboxToggle = (e) => setTermsAccepted(e.target.checked);
  const openTermsModal = () => setShowTermsModal(true);
  const closeTermsModal = () => setShowTermsModal(false);


 
  useEffect(() => {
    const signInBtn = document.querySelector("#sign-in-btn");
    const signUpBtn = document.querySelector("#sign-up-btn");
    const container = document.querySelector(".container-login");

    const handleSignUpClick = () => {
      container.classList.add("sign-up-mode");
    };

    const handleSignInClick = () => {
      container.classList.remove("sign-up-mode");
    };

    signUpBtn.addEventListener("click", handleSignUpClick);
    signInBtn.addEventListener("click", handleSignInClick);

    // Cleanup event listeners on component unmount
    return () => {
      signUpBtn.removeEventListener("click", handleSignUpClick);
      signInBtn.removeEventListener("click", handleSignInClick);
    };
  }, []);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorLog(null)

    const loginDto = {
      username: username,
      password: password,
    };

    try {
      const response = await axios.post(`${API_URL}/Login/Korisnik/Login`, loginDto, {
      });
      // Check if the response is successful
      if (response.status === 200) {
          const result = response.data; 
          const updatedLoginDto = { ...loginDto, GUID: result.GUID };
          setLoginDto(updatedLoginDto);  // Save the updated loginDto with GUID
          if (result.Firme && result.Firme.length > 0) {
              setShowFirmSelection(true);
              setFirmeList(result.Firme);
              
          }
      } 
  } catch (error) {
      setErrorLog("Nemate odabranu firmu ili niste uneli ispravne podatke");
  } finally {
      setIsLoading(false);
  }
  };

  const handleFirmSelection = (selectedFirm) => {
    if (selectedFirm) {
      const idFirme = selectedFirm.Id;
      const idBaze = selectedFirm.IdBaze;
      setShowFirmSelection(false);
      setFirmSelected(true);
      setLoggedFirm(selectedFirm.NazivFirme);
      setFirmeList(null);
    }
  };

  const handleFirmSelectionClose = () => {
    setShowFirmSelection(false);
    setFirmSelected(false);
    setLoggedFirm(''); // Reset any data if needed
    setFirmeList(null);
    setLoginDto(null)
    setUsername('')
    setPassword('')
  };

  const handleRegistrationSubmit = async (e) => {
    e.preventDefault();

    if (!termsAccepted) {
      setErrorLog("Niste prihvatili uslove koriscenja")
      return;
    }

    setIsLoading(true);
    const registrationDto = {
      userFullName: userFullName,
      userEmail: userEmail,
      userPhone: userPhone,
      companyPib: companyPib,
      companyName: companyName,
      companyLocation: companyLocation,
      companyAddress: companyAddress,
      companyActivity: companyActivity,
      companyEmployeeNum: companyEmployeeNum,
      additionalNote: additionalNote
    };

    try {
      const response = await axios.post(`${API_URL}/Login/Korisnik/Registracija`, 
        registrationDto);


      if (response.status == 200) {
          setModalPopUp("Uspešno poslat zahtev za registraciju.")
      } else  {
        setErrorLog("Došlo je do greške pri zahtevu za registraciju");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApiCallForPib = async () => {
    try {
      const response = await axios.get(`${API_URL}/Login/Korisnik/VratiNBSKlijenta`, {
        params: {companyPib}
      });
      const result = response.data;
      
    
      if (response.status === 200 && response.data != null) {
        setCompanyName(result.Naziv);
        setCompanyLocation(result.Mesto);
        setCompanyAddress(result.Adresa);  
        setIsInvalidPib(false);
      } 
      else {
        setCompanyName("");
        setCompanyLocation("");
        setCompanyAddress("");  
        setIsInvalidPib(true);
      }
    } catch (error) {
      setCompanyName("");
      setCompanyLocation("");
      setCompanyAddress("");
      setIsInvalidPib(true);
    }

  }

  const handlePasswordReset = async () => {
    const loginDto = {
      username: username
    };
    try {
      const response = await axios.post(`${API_URL}/Login/Korisnik/ResetujSifru`, loginDto);
      if (response.status === 200 ) {
        setModalPopUp("Uspešno poslat zahtev za resetovanje lozinke.")
      } 
    }
    catch (error) {
      setErrorLog("Za resetovanje lozinke morate upisati vaše korisničko ime u odgovarajuće polje i kliknuti na Zaboravljena lozinka. Upustvo za resetovanje ćete dobiti na Vaš email.");

  }
}

  return (
    <div className="container-login">
     <div className="forms-container">
        <div className="signin-signup">
        {firmSelected ? (<form onSubmit={handleLoginSubmit} className="sign-in-form">
            <h2 className="title">Podaci</h2>
            <div className="input-field">
            <FaUser className="icon-login" />
              <input
                type="text"
                placeholder="Firma"
                value={loggedFirm}
              />
            </div>
           
            <input
              type="submit"
              value={firmSelected ? "Odloguj se" : "Logovanje"}
              className="btn-login solid"
              disabled
            />
          </form>) :
        (
          <form onSubmit={handleLoginSubmit} className="sign-in-form">
            <h2 className="title">Prijava na sistem</h2>
            <div className="input-field">
            <FaUser className="icon-login" />
              <input
                type="text"
                placeholder="Korisničko ime"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="input-field">
            <FaLock className="icon-login" />
              <input
                type="password"
                placeholder="Lozinka"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="btn-login-field" >
            <input
              type="submit"
              value="Logovanje"
              className="btn-login solid"
              disabled={isLoading}
            />
             <button className="btn-reset" 
                type="button"
                onClick={handlePasswordReset}
             >
                Zaboravljena lozinka?
            </button>
            </div>
            
          </form>
        )}
       
          <form onSubmit={handleRegistrationSubmit} className="sign-up-form">
            <h2 className="title">Registraciona forma</h2>
            <div className="input-field-reg">
              <FaUser className="icon-login" />
              <input
                type="text"
                placeholder="Ime i prezime"
                value={userFullName}
                onChange={(e) => setUserFullName(e.target.value)}
                required
              />
            </div>
            <div className="input-field-reg">
              <FaEnvelope className="icon-login" />
              <input
                type="text"
                placeholder="E-mail"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-field-reg">
              <FaPhone className="icon-login" />
              <input
                type="text"
                placeholder="Telefon"
                value={userPhone}
                onChange={(e) => setUserPhone(e.target.value)}
                required
              />
            </div>
            <div className={`input-field-reg ${isInvalidPib ? 'blink' : ''}`}>
              <FaHouseUser className="icon-login" />
              <input
                type="text"
                placeholder="PIB"
                value={companyPib}
                onChange={(e) => setCompanyPib(e.target.value)}
                onBlur={handleApiCallForPib}
                required
              />
            </div>
            <div className={`input-field-reg ${isInvalidPib ? 'blink' : ''}`}>
              <FaHouseUser className="icon-login" />
              <input
                type="text"
                placeholder="Naziv firme"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
            </div>
            <div className={`input-field-reg ${isInvalidPib ? 'blink' : ''}`}>
              <FaHouseUser className="icon-login" />
              <input
                type="text"
                placeholder="Mesto"
                value={companyLocation}
                onChange={(e) => setCompanyLocation(e.target.value)}
                required
              />
            </div>
            <div className={`input-field-reg ${isInvalidPib ? 'blink' : ''}`}>
              <FaHouseUser className="icon-login" />
              <input
                type="text"
                placeholder="Adresa"
                value={companyAddress}
                onChange={(e) => setCompanyAddress(e.target.value)}
                required
              />
            </div>
            <div className="input-field-reg">
              <FaHouseUser className="icon-login" />
              <input
                type="text"
                placeholder="Delatnost"
                value={companyActivity}
                onChange={(e) => setCompanyActivity(e.target.value)}
              />
            </div>
            <div className="input-field-reg">
              <FaHouseUser className="icon-login" />
              <input
                type="text"
                placeholder="Broj zaposlenih"
                value={companyEmployeeNum}
                onChange={(e) => setCompanyEmployeeNum(e.target.value)}
              />
            </div>
            <div>
            <label className="terms-checkbox">
              Slažem se sa uslovima korišćenja
            </label>
              <input
                type="checkbox"
                id="terms-checkbox"
                checked={termsAccepted}
                onChange={handleCheckboxToggle}
                required
              />
           </div>
           <div>
           <input
              type="submit"
              value="Registracija"
              className="btn-login solid"
            />

           <button
              type="button"
              onClick={openTermsModal}
              className="btn-login solid"
              style={{ marginLeft: '10px' }}
            >
            Uslovi korišćenja
           </button>
              
             
           </div>
           
          </form>
        </div>
      </div>      
      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h2>Novi ste ovde ?</h2>
            <p>
              Ovo je registraciona forma za novog korisnika. Kliknite na donje
              dugme da pređete na registracionu formu.
            </p>
            <button className="btn-login transparent" id="sign-up-btn">
              Registracija
            </button>
          </div>
          <img
            src="/poslovni-softver/img/login.png"
            className="image"
            alt="Login Illustration"
          />
        </div>
        <div className="panel right-panel">
          <div className="content">
            <h2>Već imate nalog ?</h2>
            <p className="social-text">
              Ukoliko imate korisničko ime i lozinku kliknite ispod da pređete
              na stranicu za logovanje.
            </p>
            <button className="btn-login transparent" id="sign-in-btn">
              Logovanje
            </button>
          </div>
          <img
            src="/poslovni-softver/img/registration.svg"
            className="image register"
            alt="Illustration of Registration"
          />
        </div>
      </div>
    
      {showFirmSelection && firmeList && firmeList.length > 0 && (
        <FirmSelection 
          firmeList={firmeList}  
          loginDto = {loginDto}
          onFirmSelected={handleFirmSelection} 
          onClose={handleFirmSelectionClose}/>
       )}
        {errorLog && <ErrorModal message={errorLog} onClose={() => setErrorLog(null)} />}
        {modalPopUp && <PopUpModal message={modalPopUp} onClose={() => setModalPopUp(null)} />}
        {showTermsModal && (
                <TermsModal onClose={closeTermsModal} />
              )}
    </div>
  );
}

export default LoginForm;
