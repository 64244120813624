import React from "react";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";

function Accounting() {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(path.split("#")[1]);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };

  return (
    <>
      <section className="section-hero" id="start">
        <div className="hero">
          <div className="hero-text-box">
            <h1 className="heading-primary">
              Vaše računovodstvo zajednička briga
            </h1>
            <p className="hero-description">
              Svi znamo da je računovodstvo standard koji traje stotinama
              godina, ali ga je potrebno prilagoditi poslovanju svakog
              preduzeća. Knjigovodstvo kao njegov deo danas, moguće je pratiti
              samo kroz dobar poslovni program.
              <br />
              <br />
              Program za računovodstvo velikih firmi sa mnogo promena mora da ima
              mogućnosti podešavanja knjiženja po raznim šablonima u okviru iste
              firme i Akord softver to omogućava. <br />
              Takođe, neophodno je da softver ima konekcije sa državnim
              institucijama kao što su Poreska uprava, Centralni registar i
              Narodna banka Srbije zbog određenih provera neophodnih za tekuće
              poslovanje kao što je solventnost klijenata i naravno brži uvoz i
              automatsko knjiženje bankovnih izvoda.
              <br />
              <br />
              Obično, preduzeća imaju specifične potrebe koje su pre svega
              vezane za izveštavanja po raznim pitanjima koja su ili zakonska
              obaveza ili specifični vid praćenja poslovanja koji je nametnut od
              strane menadžmenta. <br />
              Do sada smo uspeli da prilagodimo naš poslovni softver skoro
              svakom zahtevu korisnika, a trudimo se da konsultantska podrška
              bude na najvišem nivou i zato, naši korisnici postaju naši
              prijatelji.
            </p>
          </div>
          <div className="hero-img-box">
            <picture>
              <source srcSet="/poslovni-softver/img/products/accounting.webp" type="image/webp" />
              <img
                src="/poslovni-softver/img/products/accounting.webp"
                className="hero-img"
                alt="Img of accountingman"
              />
            </picture>
          </div>
        </div>
      </section>
      <section className="section-pricing" id="pricing">
        <div className="container">
          <span className="subheading"></span>
          <h3 className="heading-secondary">
            Najbolji softver za računovodstvo i knjigovodstvo veliki firmi
          </h3>
        </div>

        <div className="container grid grid--2-cols">
          <div className="feature pricing-plan--complete">
            <p className="feature-title">
              Najbitnije o knjigovodstvenom modulu
            </p>
            <p className="feature-text">
              Program za vodjenje knjiga smo razvijali sa stručnjacima za
              knjigovodstvo sa višedecenijskim iskustbom u knjigovodstvenim
              poslovima. <br />
              Akcenat je stavljen na mogućnosti prilagođavanja softvera
              individualnim potrebama većine pojedinačni korisnika pre svega u
              velim kompanijama i preduzećima. <br />
              <br />
              Sa Akord softverom dobijate pouzdanog partnera u izveštavanju,
              kako prema državnim institucijama tako i prema menadžmentu i to uz
              najveći mogući komfor samih izvršilaca koji moraju da spreme te
              izveštaje.
              <br />
              <br />
              Omogućili smo postavljenje više kontnih planova i lake izmene u
              slučaju promene isti, definisanje sintetika, analitika i pomoćnih
              analitika, automatska knjiženja dokumenata, troškova, zarada,
              fleksibilnost u davanju naloga za knjiženje, automatsko ažuriranje
              softvera prilikom promena zakona i podzakonski akata,
              automatizaciju prijave poreza, isplata zarada, automatizaciju
              ažuriranja kursnih lista i slično.
            </p>
          </div>
          <div className="feature pricing-plan--complete">
            <p className="feature-title">
              Zašto Akord računovodstveni softver?
            </p>
            <p className="feature-text">
              Akord računovodstveni softver je razvijan od strane domaćih stručnjaka za potrebe domaćih pravnih lica. 
              Za razliku od rešenja koji su poreklom iz drugih država, nismo morali da priligođavamo neko postojeće softversko rešenje domaćem zakonadavstvu, 
              već od početka program razvijan u skladu sa domaćim zakonima i poslovnim običajima. <br /> <br />
              Softver se redovno ažurira i usklađuje sa promenama propisa. 
              Takođe, dodaju se nove funkcionalnosti kojima se olakšava svakodnevni rad naših korisnika. 
              <br />
              <br />
              Naša korisnička podrška se sastoji od stručnih ljudi sa iskustvom u knjigovodstvima malih, srednjih, velikih preduzeća, banaka, 
              javnih ustanova. Naši stručnjaci su radili operativno poslove knjigovodstva i kontrole knjiga, te imaju razumevanje potreba korisnika u praksi.
              <br />
              <br />
             Podrška našim korisnicima je
              ono što nas izdvaja od drugih. Naši konsultanti su uvek dostupni
              za sve vrste pitanja i pomoći.
              <br />
            </p>
          </div>

          <button
            onClick={() => handleButtonClick("/#cta")}
            className="btn btn--full margin-right-sm btn--industry"
          >
            Za više informacija <FaInfoCircle className="icon" />
          </button>
        </div>
      </section>
    </>
  );
}

export default Accounting;
