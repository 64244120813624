import React from "react";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";

function Trading() {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(path.split("#")[1]);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };

  return (
    <>
      <section className="section-hero" id="start">
        <div className="hero">
          <div className="hero-text-box">
            <h1 className="heading-primary">
              Veleprodaje i maloprodaje - jedan softver za sve
            </h1>
            <p className="hero-description">
              Uvek je trgovina bila glavna delatnst za stvaranje prve
              akumulacije kapitala.
              <br />
              Mnogi su počeli sa malim radnjama, a danas su veliki trgovci.
              <br />I naš poslovni softver je počeo razvoj kao program za
              trgovinu i bez obzira što danas pokriva širok spektar delatnosti,
              u trgovini je najzastupljeniji i vrlo široko prilagođen.
              <br /> <br />
              Daljim razvojem i prilagođavanjem potrebama korisnika, postao je
              program za one koji su pored trgovine krenuli u servisne
              delatnosti, usluge ili proizvodnju.
              <br />
              <br />
              Možemo slobodno reći da je Akord softver poslovni program za sve
              potrebe naše privrede.
            </p>
          </div>
          <div className="hero-img-box">
            <picture>
              <source
                srcSet="img/trolley-web.webp"
                type="image/webp"
                alt="Trollay"
              />
              <img
                src="/poslovni-softver/img/trolley-web.webp"
                className="hero-img"
                alt="Trollay"
              />
            </picture>
          </div>
        </div>
      </section>
      <section className="section-pricing" id="pricing">
        <div className="container">
          <span className="subheading"></span>
          <h3 className="heading-secondary">
            Funkcionalnosti Akord ERP softvera
          </h3>
        </div>

        <div className="container grid grid--2-cols">
          <div className="feature pricing-plan--complete">
            <p className="feature-title">Osnovne veleprodajne aktivnosti</p>
            <p className="feature-text">
              Softver za veleprodaju mora da ima, pored izrade dokumenata kao
              što su prijemnice robe, skladišnice, ponude, profakture i fakture
              i mogućnost praćenja zaliha, analize prodaje, analize kupaca,
              analize artikala, analize zaposlenih, analize poslovnih jedinica,
              analize poslovnih partnera, analize poslovnih procesa, analize
              poslovnih aktivnosti, analize poslovnih rezultata, analize
              poslovnih ciljeva, analize poslovnih strategija, analize poslovnih
              planova, analize poslovnih projekata, analize poslovnih resursa i
              sve to u potpunosti integrisano u jedan poslovni program.
            </p>
          </div>
          <div className="feature pricing-plan--complete">
            <p className="feature-title">Programsko invenarisanje zaliha</p>
            <p className="feature-text">
              Inventarisanje zaliha je jedna od najvažnijih funkcionalnosti
              poslovnog softvera. Ukoliko se zalihe ne vode tačno, posledice
              mogu biti katastrofalne. Osnovne prednosti našeg softvera su što
              podržava inventarisanje po adresama skladištenja što značajno
              ubrzava inventarisanje. Programsko inventarisanje zaliha je proces
              koji se obavlja unosom direktno u sistem, tablet uređajima i
              čitačima bar koda, a sistem prikazuje odstupanja po adresam
              skladištenja ako se zalihe tako vode. Naravno, moguće je i
              klasično inventarisanje preko papirnih lista koje se kasnije unose
              u program Akord zbog preseka stanja.
            </p>
          </div>
          <div className="feature pricing-plan--complete">
            <p className="feature-title">
              Nestandardne funkcionalnosti veleprodaje
            </p>
            <p className="feature-text">
              Akord softver podržava mnogo aktivnosti koje su posledica zahteva
              korisnika. Ukoliko neka funkcionalnost nije standardna, a korisnik
              je zainteresovan za nju, mi je razvijamo i implementiramo. Ako
              procenimo da i drugi korisnici mogu da je koriste, implementiramo
              je u standardni softver. Zato danas podržavamo aktivnosti kao što
              su preknjižavanja sa magacina na magacin, zahtev za prenos robe,
              potvrde prenosa robe, rastavnice i sastavnice, izlazi na doradu,
              dokumenta dorade, ulaza sa dorade, korekcije nabavne vrednosti,
              storniranja svih navedeni dokumenata i mnogo slični aktivnosti
              koje korisnici traže.
            </p>
          </div>
          <div className="feature pricing-plan--complete">
            <p className="feature-title">Maloprodaja - vezana skladišta</p>
            <p className="feature-text">
              Softver za maloprodaju mora da ima, pored izrade dokumenata kao
              što su prijemnice robe, skladišnice, ponude, profakture i fakture
              i mogućnost praćenja zaliha, analize prodaje, analize kupaca,
              analize artikala, analize zaposlenih, analize poslovnih jedinica,
              analize poslovnih partnera, itd. Akord softver podržava vezana
              skladišta, a to je slučaj kada se praktično u istom prostoru
              nalaze veleprodaja i maloprodaja pa nema potrebe da se posebno
              vode maloprodajne zalihe jer sve prodano što prolazi kroz kasu
              automatski se razdužuje u veleprodaji i evidentira kao promet
              maloprodaje. Naravno, rad sa kasama je podržan i implementirani su
              svi izveštaji koji se odnose na ovu vrstu prometa.
            </p>
          </div>
          <div className="feature pricing-plan--complete">
            <p className="feature-title">Internet prodaja</p>
            <p className="feature-text">
              Od prvog dana razvoja urađen je tipski šablon za internet prodaju
              koji je moguće prilagoditi potrebama korisnika. <br /> Ukoliko
              korisnik ima potrebu da se njegova već funkcionalna internet
              prodaja poveže sa Akord ERP softverom, mi to radimo, ali i nudimo
              izradu internet prodaje kao poseban projekat. U oba slučaja,
              korisnik dobija kompletno rešenje direktno vezano sa ostale
              funkcionalnosti softvera. <br /> Važno je naglasiti da je internet
              prodaja uvek povezana sa zaliham i da se zalihe uvek vode u
              realnom vremenu. <br /> Takođe, cene se ažuriraju automatski i sve
              promene koje se dešavaju u internet prodaji odmah se prenose u
              sistem za poslovanje kao i internet narudžbine koje se automatski
              obrađuju kao sve ostale narudžbine.
            </p>
          </div>
          <div className="feature pricing-plan--complete">
            <p className="feature-title">Kontroling</p>
            <p className="feature-text">
              Planiranje, praćenje i analiza poslovanja je neophodna aktivnost
              svakog preduzeća. Akord softver podržava aktivnosti koje su
              potrebne za kontroling. Uz pomoć Akord softvera korisnik može da
              prati sve poslovne aktivnosti, analizira ih i donosi odluke koje
              će uticati na buduće poslovanje. <br /> Moguće je planirati
              prodaju po prodanim objektima, prodajnim grupama, prodajnim
              mestima, prodajnim kanalima, prodajnim regionima, prodajnim
              sektorima, prodajnim tržištima, prodajnim artiklima, itd. <br />{" "}
              Ovi i drugi zahtevi su bili zahtevi korisnika i mi smo ih
              implementirali u Akord softver koji je danas jedan od najboljih
              softvera za veleprodajne i maloprodajne aktivnosti u ovom regionu.
            </p>
          </div>
          <button
            onClick={() => handleButtonClick("/#cta")}
            className="btn btn--full margin-right-sm btn--industry"
          >
            Za više informacija <FaInfoCircle className="icon" />
          </button>
        </div>
      </section>
    </>
  );
}

export default Trading;
