// import React from "react";
// import { useLocation } from "react-router-dom";
// import {
//   FaGripLinesVertical,
//   FaFacebook,
//   FaLinkedin,
import React from "react";
import { useLocation , Link} from "react-router-dom";
import {
  FaGripLinesVertical,
  FaFacebook,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import html2canvas from "html2canvas";

function JobDetails({ title, description, details }) {
  const location = useLocation();

  const handleRecommendClick = async () => {
    // Capture the screenshot
    const element = document.querySelector(".wrapper--job");
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL("image/png");

    // Create the email body
    const descriptionText = description
      .map((desc, index) => `- ${desc}`)
      .join("\n");
    const detailsText = details
      .map((detail, index) => `${detail.label}: ${detail.value}`)
      .join("\n");

    const subject = "Slobodno radno mesto u Akord Softveru";
    const body = `Pogledaj ovo, može ti biti interesantno! Pozdrav...\n\nNaziv radnog mesta:\n${title}\n\nOpis posla:\n${descriptionText}\n\nOsnovne informacije:\n${detailsText}`;
    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Open the email client with the mailto link
    window.location.href = mailtoLink;
  };

  const handleInterestedClick = () => {
    const subject = "Konkurs za posao - " + title;
    const body = `Želim da apliciram za radno mesto ${title}. Moj CV je u atačmentu. Puno pozdrava!`;
    const mailtoLink = `mailto:office@akord.rs?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Open the email client with the mailto link
    window.location.href = mailtoLink;
  };

  return (
    <div className="wrapper--job job-page">
      <section className="career--baner-job">
        <div className="wrapper--car">
          <p>
            Akord Softver
            <span className="job--space">
              <FaGripLinesVertical />
            </span>
            <span>Puno radno vreme</span>
          </p>
          <h1 id="data-title">{title}</h1>
          <div className="career--btn">
            <a
              className="btn btn--outline"
              href="#"
              onClick={handleRecommendClick}
            >
              Preporuči prijatelju
            </a>
            <a
              className="btn btn--outline"
              href="#"
              onClick={handleInterestedClick}
            >
              Zainteresovan sam
            </a>
          </div>
        </div>
      </section>
      <section className="job--description">
        <div className="wrapper">
          <div className="job--inner">
            <div className="job--inner-left">
              <h2>Opis posla</h2>
              <div id="jobdescription">
                <span id="spandesc">
                  <ul>
                    {description.map((desc, index) => (
                      <li key={index}>{desc}</li>
                    ))}
                  </ul>
                  <div className="note">
                    <span>
                      Napomena: Ovo su tipski poslovi i pored njih moguće je da
                      bude i drugih poslova koji su u okviru podrazumevane obuke
                      i školske spreme.
                    </span>
                  </div>
                </span>
              </div>
            </div>
            <div className="job--inner-right">
              <h2>Osnovne informacije</h2>
              <ul>
                {details.map((detail, index) => (
                  <li key={index}>
                    <span>{detail.label}</span>
                    <span>{detail.value}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="jobs--all">
        <Link className="btn btn--outline" to="/recruitment">
          Svi poslovi
        </Link>
      </section>
    </div>
  );
}

export default JobDetails;
