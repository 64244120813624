import React, { useEffect } from "react";
import { FaPenNib, FaFilm } from "react-icons/fa";
import VideoPlayer from "../VideoPlayer";

function SectionHero() {
  const [showVideo, setShowVideo] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState("");

  const handleVideoClick = (url) => {
    setVideoUrl(url);
    setShowVideo(true);
  };

  const handleVideoClose = () => {
    setShowVideo(false);
    setVideoUrl("");
  };

  return (
    <section className="section-hero" id="hero">
      <div className="hero">
        <div className="hero-text-box">
          <h1 className="heading-primary">
            Akord - poslovni programi po meri korisnika
          </h1>
          <p className="hero-description">
            Jedinstven i moćan poslovni program za vođenje firme i
            transformaciju načina na koji poslujete. Dizajniran za preduzeća
            svih veličina, napravljen u klaud tehnologiji od strane kompanije
            koja ceni i čuva vaše podatke.
          </p>
          <div className="hero-text-btn">
          <a href="#cta" className="btn btn--full margin-right-sm">
            Postanite korisnik <FaPenNib className="icon" />
          </a>

          <div className="">
            <button
              onClick={() => handleVideoClick("https://www.youtube.com/watch?v=IfD05nD0BRY")}
              className="btn btn--outline margin-right-sm"
            >
              Promo video...
              <FaFilm className="icon" />
            </button>

          </div>
         
          </div>
          <div className="delivered-products">
            <div className="delivered-imgs">
              <img src="/poslovni-softver/img/customers/kotroman.jpeg" alt="Vladimir Kotroman" />
              <img src="/poslovni-softver/img/customers/mila.jpg" alt="Mila Gudelj" />
              <img src="/poslovni-softver/img/customers/bane.jpg" alt="Branislav Pavlović" />
              <img src="/poslovni-softver/img/customers/branko.jpg" alt="Branko Ćulum" />
              <img src="/poslovni-softver/img/customers/peđa.jpg" alt="Peđa Dumnić" />
            </div>
            <p className="delivered-text">
              <span>300+</span>pravnih lica koriste naš program
            </p>
            <p className="delivered-text">
              <span>1.800+</span> korisnika radnim danom je sa nama!
            </p>
          </div>
        </div>
        <div className="hero-img-box">
          <img
            src="/poslovni-softver/img/prtscr-home-1200.webp"
            alt="Three times prtscn of Akord Software"
            className="hero-img"
          />
        </div>
      </div>
      {showVideo && <VideoPlayer url={videoUrl} onClose={handleVideoClose} />}
    </section>
  );
}

export default SectionHero;
