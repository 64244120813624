import React , {useEffect} from "react";
import Navbar from "./components/Navbar";
import Main from "./components/Main";
import Footer from "./components/Footer";

function App() {

    useEffect(() => {
        // Check for URL fragment and remove it if present
        if (window.location.hash) {
            window.history.replaceState(null, null, window.location.href.split('#')[0]);
        }
    }, []);
    
  return (
    <>
      <Navbar />
      <Main />
      <Footer />
    </>
  );
}

export default App;
