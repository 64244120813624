import React from "react";
import { Link } from "react-router-dom";

function JobListing({ id, title, description, link }) {
  return (
    <div className="rec--group-job">
      <h2 className="rec--group-job-title">{title}</h2>
      <p className="rec--group-job-description">{description}</p>
      <Link to={link} className="btn btn--outline">
        Pogledaj detalje
      </Link>
    </div>
  );
}

export default JobListing;
