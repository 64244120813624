// export default Dropdown;
import React from "react";
import { Link } from "react-router-dom";
import { MenuItemUsage } from "./MenuItemUsage";

function DropdownUsage({ closeMobileMenu }) {
  return (
    <ul className="dropdown-menu">
      {MenuItemUsage.map((item, index) => (
        <li key={index}>
          <Link to={item.path} className={item.cName} onClick={closeMobileMenu}>
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default DropdownUsage;
