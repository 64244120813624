import React from "react";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";

function AkordDMS() {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(path.split("#")[1]);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };

  return (
    <>
      <section className="section-hero" id="start">
        <div className="hero">
          <div className="hero-text-box">
            <h1 className="heading-primary">Upravljanje dokumentima (DMS)</h1>
            <p className="hero-description">
              DMS - Dokument menadžment sistem je bio jedan od prvih modula koji
              smo razvili još 2014 godine. <br />
              Elektronsko arhiviranje je danas postalo neophodno u svakom
              preduzeću jer se sve više poslova obavlja elektronskim putem.
              <br />
              Na ovaj način se štedi vreme, novac i prostor, a pre svega se
              smanjuje mogućnost gubitka dokumenata.
              <br />
              <br />
              Ove funkcionalnosti su praktično zasebne i svi mogu da ih koriste
              bez obzira da li koriste druge module. Pretraživanje dokumenata je
              moguće po raznim kriterijumima, a najčešće se koristi po broju,
              tipu dokumenta, lokaciji papirnog orginala, dodatnim podacima,
              datumu, vrsti dokumenta, vrsti priloga, vrsti korisnika, vrsti
              posla, vrsti arhive, vrsti klasifikacije.
              <br />
              <br />
              Svi korisnici Akord softvera su u prilici da koriste ovaj modul
              bez dodatne naknade. <br />
            </p>
          </div>
          <div className="hero-img-box">
            <picture>
              <source srcSet="/poslovni-softver/img/products/docManagment.jpg" type="image/jpg" />
              <img
                src="/poslovni-softver/img/products/docManagment.jpg"
                className="hero-img"
                alt="Img of docManagment"
              />
            </picture>
          </div>
          <button
            onClick={() => handleButtonClick("/#cta")}
            className="btn btn--full margin-right-sm btn--industry"
          >
            Za više informacija <FaInfoCircle className="icon" />
          </button>
        </div>
      </section>
    </>
  );
}

export default AkordDMS;
