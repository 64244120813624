export const MenuItemPrice = [
  {
    title: "Akord Start",
    path: "/start",
    cName: "dropdown-link",
  },
  {
    title: "Akord Micro",
    path: "/micro",
    cName: "dropdown-link",
  },
  {
    title: "Akord Basic",
    path: "/basic",
    cName: "dropdown-link",
  },
  {
    title: "Akord ERP",
    path: "/erp",
    cName: "dropdown-link",
  },
];
