export const MenuItemUsage = [
  {
    title: "Trgovina",
    path: "/trading",
    cName: "dropdown-link",
  },
  {
    title: "Komunalne delatnosti",
    path: "/utilities",
    cName: "dropdown-link",
  },
  {
    title: "Servis",
    path: "/services",
    cName: "dropdown-link",
  },
  {
    title: "Računovodstvo",
    path: "/accounting",
    cName: "dropdown-link",
  },

  {
    title: "DMS",
    path: "/document-management",
    cName: "dropdown-link",
  },
  {
    title: "CRM",
    path: "/crm",
    cName: "dropdown-link",
  },
];
