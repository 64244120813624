import React, { useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import { isValidPhoneNumber } from "libphonenumber-js";
import ErrorModal from "../elements/ErrorModal"; // Adjust the import path as needed

function SectionCTA() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [pib, setPib] = useState(""); // State for PIB
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [modalMessage, setModalMessage] = useState(""); // State to manage modal message

  const handleEmailClick = (event) => {
    event.preventDefault(); // Prevent the form from submitting

    // Validate all fields
    if (
      fullName === "" ||
      email === "" ||
      phone === "" ||
      pib === "" ||
      message === ""
    ) {
      setModalMessage("Sva polja moraju biti popunjena.");
      setShowModal(true);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setModalMessage("Unesite važeću email adresu.");
      setShowModal(true);
      return;
    }

    if (!isValidPhoneNumber(phone)) {
      setModalMessage("Unesite važeći broj telefona.");
      setShowModal(true);
      return;
    }

    const pibRegex = /^\d{9}$/;
    if (!pibRegex.test(pib)) {
      setModalMessage("PIB mora sadržati tačno 9 cifara.");
      setShowModal(true);
      return;
    }

    // Prepare the email content
    const emailBody = `
      Ime i prezime: ${fullName}
      Email adresa: ${email}
      Broj telefona: ${phone}
      PIB: ${pib}
      Poruka: ${message}
    `;

    // If all validations pass, proceed with the email link
    const mailtoLink = `mailto:office@akord.rs?subject=Contact&body=${encodeURIComponent(
      emailBody
    )}`;
    window.location = mailtoLink;

    // Clear the form fields
    setFullName("");
    setEmail("");
    setPhone("");
    setPib("");
    setMessage("");
  };

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handlePibChange = (event) => {
    setPib(event.target.value);
  };

  const handleMessageChange = (event) => {
    const messageText = event.target.value;
    if (messageText.length <= 300) {
      setMessage(messageText);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <section className="section-cta" id="cta">
      <div className="container">
        <div className="cta">
          <div className="cta-text-box">
            <h2 className="heading-secondary">Želite više informacija?</h2>
            <p className="cta-text">
              Ostavite Vaše podatke da možemo da Vas kontaktiramo. Biće nam
              zadovoljstvo da razmotrimo zajedno sa Vama koja verzija našeg
              poslovnog softvera može najbolje da zadovolji Vaše potrebe!
            </p>

            <form className="cta-form" name="sign-up" netlify="true">
              <div>
                <label htmlFor="full-name">Ime i prezime</label>
                <input
                  id="full-name"
                  type="text"
                  placeholder="Petar Petrović"
                  name="full-name"
                  value={fullName}
                  onChange={handleFullNameChange}
                  required
                />
              </div>

              <div>
                <label htmlFor="email">Email adresa</label>
                <input
                  id="email"
                  type="email"
                  placeholder="e-mejl adresa"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>

              <div>
                <label htmlFor="phone">Broj telefona</label>
                <input
                  id="phone"
                  type="tel"
                  placeholder="+381 64 123 4567"
                  name="phone"
                  value={phone}
                  onChange={handlePhoneChange}
                  required
                />
              </div>

              <div>
                <label htmlFor="pib">PIB</label>
                <input
                  id="pib"
                  type="text"
                  placeholder="Broj sa 9 cifara"
                  name="pib"
                  value={pib}
                  onChange={handlePibChange}
                  required
                />
              </div>

              <div className="message-container">
                <label htmlFor="message">Poruka</label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Vaša poruka"
                  value={message}
                  onChange={handleMessageChange}
                  maxLength="300"
                  required
                ></textarea>
                <p>{message.length}/300 karaktera</p>
              </div>

              <button className="btn btn--form" onClick={handleEmailClick}>
                Pošalji <FaEnvelope className="icon" />{" "}
              </button>
            </form>
          </div>
          <div
            className="cta-img-box"
            role="img"
            aria-label="Man with phone"
          ></div>
        </div>
      </div>
      {showModal && <ErrorModal message={modalMessage} onClose={closeModal} />}
    </section>
  );
}

export default SectionCTA;
