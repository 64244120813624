import React from "react";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";

function AkordCRM() {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(path.split("#")[1]);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };

  return (
    <>
      <section className="section-hero" id="start">
        <div className="hero">
          <div className="hero-text-box">
            <h1 className="heading-primary">CRM - Odnosi sa klijentima</h1>
            <p className="hero-description">
              CRM je softverski modul koji je razvijen za praćenje odnosa sa klijentima.
              Ovo je postalo neophodno u vreme kada je konkurencija velika i
              kada je potrebno da se klijentima pruži najbolja usluga. <br />
              Klijenti očekuju da su uvaženi, da znate sve njihove potrebe i
              žele da prepoznaju brigu o njima.
              <br />
              <br />
              Programi za praćenje odnosa sa klijentima su postali i velika
              pomoć onima koji ih koriste jer se u njima nalaze sve informacije
              o klijentima, njihovim potrebama, željama, prethodnim kontaktima,
              ponudama, narudžbinama, reklamacijama i sve to na jednom mestu.
              <br />
              <br />
              Na ovaj način i protok zaposlenih kroz kompaniju postaje rutinski
              jer je lako preuzeti tuđe klijente i na zadovoljavajući način
              nastaviti odnos sa klijentom kada dolazi do personalnih promena.
              <br />
              <br />
             CRM modul mogu koristiti svi korisnici BASIC licence bez dodatnih troškova.
            </p>
          </div>
          <div className="hero-img-box">
            <picture>
              <source srcSet="/poslovni-softver/img/products/crm.jpg" type="image/jpg" />
              <img
                src="/poslovni-softver/img/products/accounting.jpg"
                className="crm-img"
                alt="Img of CRM"
              />
            </picture>
          </div>
          <button
            onClick={() => handleButtonClick("/#cta")}
            className="btn btn--full margin-right-sm btn--industry"
          >
            Za više informacija <FaInfoCircle className="icon" />
          </button>
        </div>
      </section>
    </>
  );
}

export default AkordCRM;
