import React from "react";
import { Link } from "react-router-dom";
import { MenuItemPrice } from "./MenuItemPrice";

function DropdownPrice({ closeMobileMenu }) {
  return (
    <ul className="dropdown-menu">
      {MenuItemPrice.map((item, index) => (
        <li key={index}>
          <Link to={item.path} className={item.cName} onClick={closeMobileMenu}>
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default DropdownPrice;
